/* eslint-disable import/prefer-default-export */
import dynamicLoad from 'dottom@common/dynamic-loading';
import cssLoad from 'dottom@common/css-load';
import onReady from 'dottom@common/on-ready';

// const InitColorPicker = new CustomEvent('InitColorPicker');

const prepareAdditionalLoad = (loader, additionalLoad) => {
  additionalLoad.forEach((file) => {
    if (file.endsWidth('.js')) {
      loader.push(dynamicLoad(file));
    } else if (file.endsWith('.css')) {
      loader.push(cssLoad(file));
    }
  });
};

const loadColorPickerJS = (additionalLoad = []) => {
  if (!window.Pickr) {
    const loader = [
      cssLoad('/assets/css/pickr/pickr.min.css'),
      dynamicLoad('/assets/js/pickr/pickr.min.js'),
    ];
    prepareAdditionalLoad(loader, additionalLoad);
    return Promise.all(loader);
  }
  if (additionalLoad.length) {
    const loader = [];
    prepareAdditionalLoad(loader, additionalLoad);
    return Promise.all(loader);
  }
  return Promise.resolve();
};

export const loadColorPicker = (additionalLoad = []) => (
  new Promise((resolve, reject) => {
    onReady(() => {
      loadColorPickerJS(additionalLoad).then(() => {
        resolve();
      }).catch((reason) => {
        reject(reason);
      });
    });
  })
);

export const createInlineColorPicker = (options = []) => (
  new Promise((resolve, reject) => {
    const {
      additionalLoad = [],
      components,
      ...pickrOptions
    } = options;

    const {
      interactions,
      ...componentOptions
    } = components;

    loadColorPicker(additionalLoad).then(() => {
      const container = document.createElement('div');
      const dummy = document.createElement('div');
      container.appendChild(dummy);
      const { Pickr } = window;
      const pickr = new Pickr({
        useAsButton: true,
        inline: true,
        showAlways: true,
        components: {
          preview: true,
          opacity: true,
          hue: true,

          interaction: {
            input: true,
            ...interactions,
          },
          ...componentOptions,
        },
        ...pickrOptions,
        el: dummy,
      });
      resolve({
        container,
        pickr,
      });
    }).catch((reason) => {
      reject(reason);
    });
  })
);
