import $ from 'jquery';
import createModal, { getSettings } from 'dottom@common/elements/modals';
import { addSvg } from 'dottom@common/svg';
import { setContent, setTriggers } from './lightbox/handlers';
import { setControls } from './lightbox/controls';

const templates = {
  header: `<div class="modal-bar modal-header-bar">
  <div class="modal-bar-title">{{title}}</div>
  <div class="modal-bar-btns">{{close_btn}}</div>
</div>`,
  footer: `<div class="modal-bar modal-footer-bar">
  <div class="modal-bar-spacer"></div>
  <div class="modal-bar-btns">{{footer}}</div>
  <div class="modal-bar-spacer"></div>
</div>
{{controls}}`,
  closeButton: `<button class="btn btn-circle btn-color-grey block-dropshadow" data-dismiss="modal">${addSvg('times')}</button>`,
  controls: `<div class="modal-control">
  <div class="modal-control-left"><button class="btn btn-circle btn-lg btn-color-grey block-dropshadow" data-control="left">${addSvg('chevron-left')}</button></div>
  <div class="modal-control-right"><button class="btn btn-circle btn-lg btn-color-grey block-dropshadow" data-control="right">${addSvg('chevron-right')}</button></div>
</div>`,
};

const galleryCache = {};

const createLightbox = (options = {}) => {
  const opt = getSettings(options);

  const {
    link,
    gallery,
    items,
    type = null,
  } = opt;

  let {
    title = null,
  } = opt;

  const state = {
    isTransition: false,
  };

  opt.transitionDuration = opt.transitionDuration || 100;
  opt.fadeDuration = opt.fadeDuration || 100;
  opt.transitionType = opt.transitionType || 'linear';
  opt.fadeType = opt.fadeType || 'linear';

  let file;
  let fileData;
  let fileItems = [];
  let fileType = type;
  let index = 0;

  const setIndex = (newIndex) => {
    index = newIndex;
  };
  const getIndex = () => index;

  if (gallery) {
    const galleryItems = galleryCache[gallery];
    if (galleryItems && galleryItems.items[link]) {
      fileItems = galleryItems.items;
      fileData = fileItems[link];
      index = link;
    }
  }
  if (!fileData && items) {
    fileItems = items;
    fileData = items[link];
    index = link;
    if (gallery) {
      galleryCache[gallery] = galleryCache[gallery] || {};
      galleryCache[gallery].items = items;
    }
  }
  if (fileData) {
    if (typeof fileData === 'string') {
      file = fileData;
    } else {
      file = fileData.url;
      title = fileData.title || title;
      fileType = fileData.type || type;
    }
  }
  if (!file) {
    file = link;
  }

  let footer = (opt.templates.blocks.lightbox && opt.templates.blocks.lightbox.footer)
  || templates.footer;

  footer = footer.replace(/{{\s*controls\s*}}/, fileItems.length > 1
    ? (opt.templates.blocks.lightbox && opt.templates.blocks.lightbox.controls)
    || templates.controls
    : '');

  const $modal = createModal({
    modalClassName: 'modal-dialog modal-contain modal-max modal-padded',
    containerClassName: 'modal fade modal-dynamic modal-dynamic-centered',
    templates: {
      blocks: {
        header: (opt.templates.blocks.lightbox && opt.templates.blocks.lightbox.header)
          || templates.header,
        footer,
      },
      closeButton: (opt.templates.blocks.lightbox && opt.templates.blocks.lightbox.closeButton)
        || templates.closeButton,
    },
    ...options,
  });

  $modal.find('.modal-body').css('opacity', 0);

  const controlData = {
    state,
    setIndex,
    getIndex,
    items: fileItems,
    defaultTitle: opt.title,
    type,
    $modal,
    options: opt,
  };

  setTriggers($modal, opt, controlData);

  setContent({
    file,
    title,
    fileType,
  }, $modal, state, opt);

  $modal.find('[data-control]').each(function setControlsHandler() {
    setControls.call(this, controlData);
  });

  return $modal;
};

$(document).on('click', '[data-modal-lightbox]', function initLightbox() {
  const $this = $(this);
  const gallery = $this.data('modalLightbox');

  if (gallery) {
    // TODO
  } else {
    const url = $this.attr('href');
    const title = $this.attr('title') || '';
    const options = {
      link: url,
      title,
    };

    if ($this.data('type')) {
      options.type = $this.data('type');
    }

    createLightbox(options);
  }
});

export default createLightbox;
