import { EVENTS as CMS_EVENTS } from 'dottom@shared/events';

export default class MessageHandler {
  constructor() {
    this.messageEvents = {};
    this.receiveMessage = this.receiveMessage.bind(this);
    this.unloadMessageHandler = this.unloadMessageHandler.bind(this);
  }

  receiveMessage(event) {
    const eventData = event.data;
    const eventName = (typeof eventData === 'string' || eventData instanceof String) ? eventData : eventData.event;

    if (this.messageEvents[eventName]) {
      this.messageEvents[eventName](eventData, event.source);
    }
  }

  addMessageHandler(event, callback) {
    this.messageEvents[event] = callback;
  }

  getMessageHandler(event) {
    return this.messageEvents[event] || null;
  }

  initializeEventHandler() {
    window.addEventListener('message', this.receiveMessage, false);
    window.addEventListener(CMS_EVENTS.CMS_PAGE_UNLOAD, this.unloadMessageHandler);
  }

  unloadMessageHandler() {
    window.removeEventListener('message', this.receiveMessage);
    window.removeEventListener(CMS_EVENTS.CMS_PAGE_UNLOAD, this.unloadMessageHandler);
  }
}
