import $ from 'jquery';
import { setContent } from './handlers';

function changeContents(data) {
  const {
    state,
    options,
    position,
    setIndex,
    getIndex,
    items,
    defaultTitle,
    type,
    $modal,
  } = data;

  if (state.isTransition) {
    return;
  }

  let index = getIndex();

  switch (position) {
    case 'left':
      index -= 1;
      if (index < 0) {
        index = items.length - 1;
      }
      break;
    case 'right':
      index += 1;
      if (index >= items.length) {
        index = 0;
      }
      break;
    default:
      if (Number.isNaN(+position)) {
        index = +position;
      }
      break;
  }
  setIndex(index);

  const currentData = items[index];
  const contentData = {
    title: defaultTitle,
    type,
  };
  if (typeof currentData === 'string') {
    contentData.file = currentData;
  } else {
    contentData.file = currentData.url;
    contentData.title = currentData.title || contentData.title;
    contentData.type = currentData.type || contentData.type;
  }
  setContent(contentData, $modal, state, options);
}

export function setControls(data) {
  const $control = $(this);
  const position = $control.data('control');

  $control.on('click', changeContents.bind($control, {
    ...data,
    position,
  }));
}

export const setKeyboardControls = (data, event) => {
  switch (event.which) {
    case 37:
    case 39:
      changeContents({
        ...data,
        position: event.which === 37 ? 'left' : 'right',
      });
      break;
    default:
      break;
  }
};
