/**
 * An exception class that is used whenever there's an invalid DOM element specified
 */
export default class InvalidDOMElementException extends Error {
  constructor(message = null) {
    super(message);
    this.name = 'InvalidDOMElementException';
    this.message = message ? `An invalid DOM element was provided: ${message}` : 'An invalid DOM element was provided';
    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, InvalidDOMElementException);
    } else {
      this.stack = (new Error()).stack;
    }
  }
}
