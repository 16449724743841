const $ = require('jquery');

/* csrf */
function regenerateCSRF() {
  // get regenerated csrf
  $.ajax({
    type: 'GET',
    dataType: 'json',
    url: '/pages/ajax_post_controller/get_csrf',
    success(data) {
      if (data.csrf_token) {
        $('input[name="csrf_test_name"]').val(data.csrf_token);
      }
      return false;
    },
  });
}

module.exports = regenerateCSRF;
