import $ from 'jquery';
import { debounce } from 'dottom@common/throttle';
import images from './images';
import { setKeyboardControls } from './controls';

const handlers = {
  images,
};

function unloadContent(options = {}) {
  const $modal = $(this);
  const $content = $modal.find('.modal-body');
  if (+$content.css('opacity') > 0) {
    $content.stop(true).fadeTo(options.fadeDuration || 100, 0, options.fadeType || 'linear', () => {
      $content.empty();
      $modal.trigger('unloadedContent');
    });
  } else {
    $modal.trigger('unloadedContent');
  }
}

function loadedContent(options = {}) {
  const $modal = $(this);
  const $content = $modal.find('.modal-body');
  if (+$content.css('opacity') === 0) {
    $content.stop(true).fadeTo(options.fadeDuration || 100, 1, options.fadeType || 'linear', () => {
      $modal.trigger('visibleContent');
    });
  }
}

export const setTriggers = ($modal, options, controlData) => {
  const unloadContentHandler = unloadContent.bind($modal[0], options);
  const loadedContentHandler = loadedContent.bind($modal[0], options);
  $modal.on('unloadContent', unloadContentHandler);
  $modal.on('loadedContent', loadedContentHandler);

  const keyboardEventHandler = setKeyboardControls.bind($modal, controlData);

  $(document).on('keydown', keyboardEventHandler);

  $modal.on('hide.bs.modal', () => {
    $modal.trigger('unloadContent');
    $modal.off('unloadContent', unloadContentHandler);
    $modal.off('loadedContent', loadedContentHandler);

    $(document).off('keydown', keyboardEventHandler);
  });
  $modal.on('hidden.bs.modal', () => {
    $modal.find('.modal-body').stop(true);
  });
};

export const detectType = (link) => {
  const handlerKeys = Object.keys(handlers);

  for (let keyIndex = 0; keyIndex < handlerKeys.length; keyIndex += 1) {
    const handlerId = handlerKeys[keyIndex];
    const handler = handlers[handlerId];

    if (handler.detect && handler.detect(link)) {
      return handlerId;
    }
  }
  return null;
};

export const beforeRender = (type, link) => (
  new Promise((resolve, reject) => {
    if (handlers[type] && handlers[type].beforeRender) {
      handlers[type].beforeRender(link, resolve, reject);
    } else {
      resolve(link);
    }
  })
);

export const afterRender = (type, data, $modal) => {
  if (handlers[type] && handlers[type].afterRender) {
    handlers[type].afterRender(data, $modal);
  }
};

export const setOnResize = (type, $modal) => {
  if (handlers[type] && handlers[type].onResize) {
    const onResizeHandler = debounce(handlers[type].onResize.bind($modal), 100);
    $(window).on('resize', onResizeHandler);

    $modal.one('unloadContent', () => {
      $(window).off('resize', onResizeHandler);
    });
  }
};

export const addHandler = (handlerId, handlerData) => {
  handlers[handlerId] = handlerData;
};

export const setContent = (fileData, $modal, state, options) => {
  const {
    file,
    title,
  } = fileData;

  let {
    type = null,
  } = fileData;

  state.isTransition = true;

  const $content = $modal.find('.modal-body');

  const contentSize = {
    width: $content.width(),
    height: $content.height(),
  };

  if (!type) {
    type = detectType(file);
  }

  const beforeRenderPromise = beforeRender(type, file);

  beforeRenderPromise.then((data) => {
    $modal.one('unloadedContent', () => {
      const emptySize = {
        width: $content.width(),
        height: $content.height(),
      };

      const realSize = {
        width: contentSize.width - emptySize.width,
        height: contentSize.height - emptySize.height,
      };

      afterRender(type, data, $modal);

      $modal.find('.modal-bar-title').text(title);

      setOnResize(type, $modal);

      if (options.transitionDuration && realSize.width && realSize.height) {
        const newSize = {
          width: $content.width(),
          height: $content.height(),
        };

        $content.width(realSize.width);
        $content.height(realSize.height);

        $content.animate({
          width: `${newSize.width}px`,
          height: `${newSize.height}px`,
        }, options.transitionDuration, options.transitionType || 'linear', () => {
          $content.css('width', '');
          $content.css('height', '');

          $modal.trigger('loadedContent');
          $modal.one('visibleContent', () => {
            state.isTransition = false;
          });
        });
      } else {
        $modal.trigger('loadedContent');
        $modal.one('visibleContent', () => {
          state.isTransition = false;
        });
      }
    });

    $modal.trigger('unloadContent');
  });
};
