const loaded = {};
const dynamicLoad = (url, verifyFunction = null) => {
  // eslint-disable-next-line camelcase
  const trueUrl = (__webpack_public_path__ !== '/assets/' && /^\/assets\//.test(url)
    ? url.replace(/^\/assets\//, __webpack_public_path__)
    : url
  );

  if (loaded[trueUrl]) {
    return loaded[trueUrl].promise;
  }

  const promise = new Promise((resolve, reject) => {
    loaded[trueUrl] = {
      resolve,
      reject,
    };
  });

  loaded[trueUrl].promise = promise;

  const existingScript = document.querySelector(`script[src$="${trueUrl}"]`);

  let script;

  if (existingScript) {
    if (!verifyFunction || verifyFunction()) {
      loaded[trueUrl].resolve();
      return promise;
    }

    script = existingScript;
  } else {
    script = document.createElement('script');
    script.type = 'text/javascript';
    script.charset = 'utf-8';
    script.async = true;
    script.timeout = 120000;
    script.src = trueUrl;
  }

  let timeout;
  let onScriptComplete;

  const onScriptSuccess = () => {
    loaded[trueUrl].resolve();
    onScriptComplete();
  };

  const onScriptError = () => {
    loaded[trueUrl].reject();
    onScriptComplete();
  };

  onScriptComplete = () => {
    script.removeEventListener('load', onScriptSuccess);
    script.removeEventListener('error', onScriptError);
    clearTimeout(timeout);
  };

  script.addEventListener('load', onScriptSuccess);
  script.addEventListener('error', onScriptError);
  timeout = setTimeout(onScriptError, 120000);

  if (!existingScript) {
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }

  return promise;
};

export default dynamicLoad;
