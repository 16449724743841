import { sprintf } from 'sprintf-js';
import { isPlainObject } from '../deep-copy';

export default function sprintformat(message, params) {
  if (isPlainObject(params)) {
    return Object.keys(params).reduce((prev, current) => (
      prev.replaceAll(`{${current}}`, params[current])
    ), message);
  }
  return sprintf(message, ...params);
}
