import MessageHandler from './message-handler';

export default class TwoWayIframeChild {
  constructor() {
    this.messageHandler = new MessageHandler();
    this.messageHandler.initializeEventHandler();
  }

  sendMessage(event, source) {
    if (!source || !source.location.href) {
      return;
    }
    source.postMessage(event, source.location.href);
  }

  addMessageHandler(event, callback) {
    this.messageHandler.addMessageHandler(event, callback);
  }

  getMessageHandler(event) {
    return this.messageHandler.getMessageHandler(event);
  }
}
