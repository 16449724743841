const $ = require('jquery');

/* JQUERY */

$(() => {
  // calendar
  let enableCalendar = $('#eventCalendarHumanDate').length;
  if (enableCalendar) {
    $('#eventCalendarHumanDate').eventCalendar({
      eventsjson: '/planning/events/',
      eventsLimit: 1,
    });
  }
  // calender free
  enableCalendar = $('#eventCalendarFree').length;
  if (enableCalendar) {
    $('#eventCalendarFree').eventCalendar({
      eventsjson: 'planning/events/',
    });
  }

  // submit button merchants
  function enableButton() {
    $('#check_data').removeAttr('disabled');
  }
  $('a#check_data').on('click', (event) => {
    event.preventDefault();
    $('#check_data').attr('disabled', 'disabled');
    setTimeout(enableButton, 10000);
  });

  // remove upload from form list
  $(document).on('click', 'a.remove_upload', function onDocumentRemoveUploadClick() {
    // remove upload
    $(this).parent().remove();
    // check if there is a other upload that is not allowed
    if ($('form a').hasClass('remove_upload')) {
      return true;
    }
    // clear disabled class from submit
    $('form input[type=submit]').removeClass('disabled');
    return false;
  });

  /*= ===========
   *= CALENDAR =
   *============
   */
  if ($('.event-calendar, .picker-calendar').length > 0) {
    $('<link href="/assets/css/clndr/clndr.css" rel="stylesheet" type="text/css">').insertBefore($('head').find('link[href*="css/default_style"]').last());
    $('<script src="/assets/js/underscore-min.js"></script>').appendTo('body');
    $('<script src="/assets/js/moment-with-locales.min.js""></script>').appendTo('body');
    $('<script src="/assets/js/clndr/clndr.min.js"></script>').appendTo('body');
    $('<script src="/assets/js/clndr/clndr.init.min.js"></script>').appendTo('body');
  }

  /* ================
   * = BLOG ISOTOPE =
   * ================
   */
  // Isotope blog grid
  if ($('.blog-grid').length) {
    // init Isotope
    const $grid = $('.blog-grid').isotope({
      itemSelector: '.grid-item',
      getSortData: {
        name: '.name',
      },
    });

    // sort items on button click
    $('.sort-by-button-group').on('click', 'button', function onClickSortByButtonGroup() {
      const sortByValue = $(this).attr('data-sort-by');
      $grid.isotope({ sortBy: sortByValue });
    });
  }

  /* ===================
   * = WEBSHOP ISOTOPE =
   * ===================
   */
  // Webshop frontpage categories grid / isotope
  if ($('.webshop-grid').length) {
    // init Isotope
    const $grid = $('.webshop-grid').isotope({
      itemSelector: '.grid-item',
      percentPosition: true,
      sortBy: 'random',
      getSortData: {
        name: '.name',
        name_high: '.name',
        category: '[data-category]',
        category_high: '[data-category]',
        price: '[data-filter-price] parseFloat',
        price_high: '[data-filter-price] parseFloat',
      },
      sortAscending: {
        name: true, // name ascendingly
        name_high: false, // name descendingly
        category: false, // category descendingly
        category_high: true, // category ascendingly
        price: false, // category descendingly
        price_high: true, // category ascendingly
      },
    });

    // sort items on button click
    $(document).on('click', '.sort-by-button-group button', function onClickSortByButtonGroupWebshop() {
      const sortByValue = $(this).attr('data-sort-by');
      $grid.isotope({ sortBy: sortByValue });
    });
  }

  /*= ===========
   *= FANCYBOX =
   *============
   */
  if (!$.fancybox.helpers.media) {
    $.getScript('/assets/js/fancybox/helpers/jquery.fancybox-media.js');
  }
  $('.fancybox').filter(function filterFancyboxImgGallery() {
    if ($(this).closest('.front_img_gallery').length > 0) {
      return false;
    }
    return true;
  }).fancybox({
    helpers: {
      media: true,
    },
  });
});

/* JS Functions */

/* allowfullscreen on iframe elements */
/* var x = document.getElementById("page");
var y = x.getElementsByTagName("iframe");
var i;
for (i = 0; i < y.length; i++) {
    //add attribute just to see if this is working
    y[i].setAttribute('allowFullScreen', '');

    if (y[i].requestFullscreen) {
        y[i].requestFullscreen();
    } else if (y[i].msRequestFullscreen) {
        y[i].msRequestFullscreen();
    } else if (y[i].mozRequestFullScreen) {
        y[i].mozRequestFullScreen();
    } else if (y[i].webkitRequestFullscreen) {
        y[i].webkitRequestFullscreen();
    }
} */

// get upload form file extension
function getFileExtension(filename) {
  return filename.substr((~-filename.lastIndexOf('.') >>> 0) + 2);
}
window.getFileExtension = getFileExtension;

// visual feedback on supported file type
function isUploadSupported(fileName) {
  const allowedTypes = 'pdf,ppt,pptx,xls,xlxs,doc,docx,jpg,jpeg,png,webp,gif,eps';
  const fileExtensions = allowedTypes.toLowerCase().split(/[\s,]+/g);
  const ext = fileName.split('.').pop().toLowerCase();
  if (allowedTypes !== '*' && $.inArray(ext, fileExtensions) < 0) {
    return false;
  }
  return true;
}

// visual feedback file upload fields
function readURL(input) {
  if (input.files && input.files[0]) {
    for (let i = 0; i < input.files.length; i += 1) {
      const file = input.files[i];
      const upload = file.name;
      const sizeKB = file.size / 1024;
      let sizeStr = '-';
      if (parseInt(sizeKB, 10) > 1024) {
        const sizeMB = sizeKB / 1024;
        sizeStr = `${sizeMB.toFixed(2)} MB`;
      } else {
        sizeStr = `${sizeKB.toFixed(2)} KB`;
      }
      // check allowed filetype
      if (!isUploadSupported(upload)) {
        $(input).parent().parent().append(`<p class="upload-info clearfix"><i class="fa fa-ban"></i> ${upload} (${sizeStr}) - Upload niet toegestaan! <a href="javascript:void(0);" class="btn btn-danger btn-xs pull-right remove_upload"><i class="fa fa-times"></i></a></p>`);
        // disable all submit buttons with class
        $('input[type=submit]').addClass('disabled');
      } else {
        $(input).parent().parent().append(`<p class="upload-info"><i class="fa fa-check"></i> ${upload} (${sizeStr}) <a href="javascript:void(0);" class="btn btn-danger btn-xs pull-right remove_upload"><i class="fa fa-times"></i></a></p>`);
      }
    }
  }
}
window.readURL = readURL;

/* END guestbook js functions */

/* Extended fancybox plugin */
(function extendFancyBox(F) {
  F.transitions.resizeIn = function setFancyboxResizeIn() {
    const { previous } = F;
    const { current } = F;
    const startPos = previous.wrap.stop(true).position();
    const endPos = $.extend({ opacity: 1 }, current.pos);

    startPos.width = previous.wrap.width();
    startPos.height = previous.wrap.height();

    previous.wrap.stop(true).trigger('onReset').remove();

    delete endPos.position;

    current.inner.hide();

    current.wrap.css(startPos).animate(endPos, {
      duration: current.nextSpeed,
      easing: current.nextEasing,
      step: F.transitions.step,
      complete() {
        // eslint-disable-next-line no-underscore-dangle
        F._afterZoomIn();

        current.inner.fadeIn('fast');
      },
    });
  };
}($.fancybox));

/* History compatibility */
$(window).on('load', () => {
  if (!window.history && window.History) {
    window.history = window.History;
  }
  if (!window.History && window.history) {
    window.History = window.history;
  }
});

/* Focusable elements */
$(document).on('click', '[tabindex]', function onDocumentTabindexClick() {
  if (!$(this).is(':focus') && $(this).has(':focus').length === 0) {
    $(this)[0].focus();
  }
});
