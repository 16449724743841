import dynamicLoad from 'dottom@common/dynamic-loading';

const $ = require('jquery');

/* ===========
 * = MASONRY =
 * ===========
 */
const $masonryElements = $('[data-masonry]');
if ($masonryElements.length > 0) {
  dynamicLoad('/assets/js/masonry.pkgd.min.js').then(() => {
    // eslint-disable-next-line func-names
    $masonryElements.each(function () {
      const options = {
        itemSelector: $(this).data('masonry'),
      };
      $(this).masonry(options);
    });
  });
}
