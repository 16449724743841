import { sprintf } from 'sprintf-js';
import setCommon from 'dottom@common/set-common';
import convertAccents from 'dottom@common/text/convert-accent';
import urlTitle from 'dottom@common/text/url-title';
import helpers from 'dottom@common/helpers';
import showMessage from 'dottom@common/elements/modals';
import showLightbox from 'dottom@common/elements/modals/lightbox';
import * as colorPicker from 'dottom@common/elements/colorpicker';
import initDottomProps from './dottom-props';
import { autoloadRuffle } from '../common/elements/ruffle';

const cmsDefaults = require('dottom@common/defaults');
const { addSvg, getSvg } = require('dottom@common/svg');
const { default: ajaxFetch, setRegenerateCSRF: setAjaxFetchRegenerateCSRF } = require('dottom@shared/jquery-common/ajax-fetch');
const { throttle, debounce } = require('dottom@common/throttle');
const {
  default: createAlert,
  displayAlert,
} = require('dottom@common/elements/alertbox');

setCommon(cmsDefaults, 'cmsDefaults');
setCommon(throttle, 'throttle');
setCommon(debounce, 'debounce');
setCommon(createAlert, 'createAlert');
setCommon(displayAlert, 'displayAlert');
setCommon(showMessage, 'showMessage');
setCommon(addSvg, 'addSvg');
setCommon(getSvg, 'getSvg');
setCommon(ajaxFetch, 'ajaxFetch');
setCommon(convertAccents, 'convertAccents');
setCommon(urlTitle, 'urlTitle');
setCommon(colorPicker, 'colorPicker');
setCommon(helpers, 'helpers');

const setModal = initDottomProps('showMessage');

setModal(showLightbox, 'lightbox');
setCommon(sprintf, 'sprintf');

// eslint-disable-next-line import/prefer-default-export
export const setRegenerateCSRF = (regenerateCSRF) => {
  setAjaxFetchRegenerateCSRF(regenerateCSRF);
};

autoloadRuffle();
