const $ = require('jquery');

window.dottom = window.dottom || {};

const setCommon = (module, moduleName) => {
  $[moduleName] = module;
  window.dottom[moduleName] = module;
};

export default setCommon;
