const $ = require('jquery');

window.dottom = window.dottom || {};

module.exports = (mod) => (value, key = false) => {
  if (!key) {
    $[mod] = value;
    window.dottom[mod] = value;
  }
  $[mod][key] = value;
  window.dottom[mod][key] = value;
};
