import serialize from 'dottom@shared/serialize';
import createAlert from 'dottom@common/elements/alertbox';
import { getPathname } from '../../common/helpers/urls';

const $ = require('jquery');

window.dottom = window.dottom || {};

let funcRegenerateCSRF = null;

const ajaxFetchCallbacks = {};

export const addAjaxFetchCallback = (type, callback) => {
  ajaxFetchCallbacks[type] = ajaxFetchCallbacks[type] ?? [];
  ajaxFetchCallbacks[type].push(callback);
};

const expandStore = (store) => [store.response, store.status, store.headers, store];

/**
 * Does an AJAX fetch request
 * @param {type} data
 * @param {type} options
 * @param {type} callbacks
 * @returns {Boolean}
 */
const ajaxFetch = (data, opts = {}, callbacks = {}, regenerateCSRF = funcRegenerateCSRF) => {
  if (!data) {
    return false;
  }
  const options = {
    overlay: true,
    hideModal: '.modal',
    scrollMode: 2,
    showMessage: true,
    asBlob: false,
    ...opts,
  };

  if (options.overlay) {
    $('#total-overlay').show();
  }
  let formData = null;
  let realUrl = data.url;

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    ...data.headers,
  };

  const init = {
    method: data.method || data.type || 'POST',
    headers,
  };

  if ((data.payload || data.data)) {
    if (typeof (data.payload || data.data) === 'string') {
      formData = (data.payload || data.data);
    } else if ((data.data || data.payload) instanceof FormData || (data.headers && headers['Content-Type'] === 'multipart/form-data')) {
      formData = data.data || data.payload || new FormData();
      delete headers['Content-Type'];
    } else {
      const payload = { ...(data.data || {}), ...(data.payload || {}) };
      formData = serialize(payload);
    }
  }

  if (init.method.toUpperCase() !== 'GET' || typeof formData !== 'string') {
    init.body = formData;
  } else {
    const splitUrl = realUrl.split(/\?/);
    realUrl = splitUrl.shift();
    let remainder = '';
    if (splitUrl.length > 0) {
      remainder = `${remainder}?${splitUrl.join('?')}&`;
    }
    remainder = `${remainder}${formData}`;
    realUrl = `${realUrl}${remainder}`;
  }

  const store = {
    response: null,
  };
  const privateStore = {};
  privateStore.promise = new Promise((resolve) => {
    privateStore.resolve = resolve;
  });

  const expandStoreLocal = expandStore.bind(null, store);

  return fetch(realUrl, init).then((res) => {
    store.headers = res.headers;
    store.status = res.status;
    store.url = getPathname(res.url);

    res.clone().json().then((response) => {
      // Store response to store
      store.response = response;

      if (ajaxFetchCallbacks.onBeforeFirst) {
        ajaxFetchCallbacks.onBeforeFirst.forEach((callback) => {
          callback.call(this, ...expandStoreLocal());
        });
      }

      if (callbacks.onFirst) {
        callbacks.onFirst.call(this, ...expandStoreLocal());
      }

      if (ajaxFetchCallbacks.onFirst) {
        ajaxFetchCallbacks.onFirst.forEach((callback) => {
          callback.call(this, ...expandStoreLocal());
        });
      }

      // Set response
      if (options.showMessage) {
        createAlert({
          type: store.response.message_type,
          message: store.response.message,
          messageTitle: store.response.message_title,
          messageIcon: store.response.message_icon || undefined,
          position: options.alertPosition || undefined,
        });
      }
      switch (store.response.message_type) {
        case 'message':
        case 'success':
          if (callbacks.onSuccess) {
            callbacks.onSuccess.call(this, ...expandStoreLocal());
          }
          break;
        case 'info':
          if (callbacks.onInfo) {
            callbacks.onInfo.call(this, ...expandStoreLocal());
          }
          break;
        case 'error':
          if (callbacks.onError) {
            callbacks.onError.call(this, ...expandStoreLocal());
          }
          break;
        default:
          if (callbacks.onWarning) {
            callbacks.onWarning.call(this, ...expandStoreLocal());
          }
          break;
      }

      if (callbacks.onAfterSuccess) {
        callbacks.onAfterSuccess.call(this, ...expandStoreLocal());
      }
      if (ajaxFetchCallbacks.onAfterSuccess) {
        ajaxFetchCallbacks.onAfterSuccess.forEach((callback) => {
          callback.call(this, ...expandStoreLocal());
        });
      }
      privateStore.resolve();
    }).catch(() => {
      const resType = options.asBlob ? 'blob' : 'text';
      res[resType]().then((response) => {
        let filename = store.url.split('/').slice(-1)[0];
        const contentDisposition = store.headers.get('Content-Disposition');
        const contentName = contentDisposition && contentDisposition.match(/filename="(.*?)"/);
        if (contentName) {
          ([, filename] = contentName);
        }

        store.response = {
          [resType]: response,
          filename,
        };

        if (ajaxFetchCallbacks.onBeforeFirst) {
          ajaxFetchCallbacks.onBeforeFirst.forEach((callback) => {
            callback.call(this, ...expandStoreLocal());
          });
        }

        if (callbacks.onFirst) {
          callbacks.onFirst.call(this, ...expandStoreLocal());
        }

        if (ajaxFetchCallbacks.onFirst) {
          ajaxFetchCallbacks.onFirst.forEach((callback) => {
            callback.call(this, ...expandStoreLocal());
          });
        }

        if (res.ok) {
          if (callbacks.onSuccess) {
            callbacks.onSuccess.call(this, ...expandStoreLocal());
          }
        } else
        if (callbacks.onError) {
          callbacks.onError.call(this, ...expandStoreLocal());
        }

        if (callbacks.onAfterSuccess) {
          callbacks.onAfterSuccess.call(this, ...expandStoreLocal());
        }
        if (ajaxFetchCallbacks.onAfterSuccess) {
          ajaxFetchCallbacks.onAfterSuccess.forEach((callback) => {
            callback.call(this, ...expandStoreLocal());
          });
        }
        privateStore.resolve();
      });
    });
  }).catch((response) => {
    if (options.showMessage) {
      if (response.responseJSON) {
        store.response = {
          type: response.responseJSON.message_type,
          message: response.responseJSON.message,
          messageTitle: response.responseJSON.message_title,
          messageIcon: response.responseJSON.message_icon || undefined,
          position: options.alertPosition || undefined,
        };
      } else if (response.responseText) {
        store.response = {
          type: 'error',
          message: $.ajaxFetch.defaults.message || 'Error',
          messageTitle: $.ajaxFetch.defaults.messageTitle || 'Unknown error',
        };
      }
      if (store.response) {
        createAlert(store.response);
      } else if (response.responseText) {
        store.response = {
          text: response.responseText,
        };
      }
    }
    if (ajaxFetchCallbacks.onBeforeFirst) {
      ajaxFetchCallbacks.onBeforeFirst.forEach((callback) => {
        callback.call(this, ...expandStoreLocal());
      });
    }

    if (callbacks.onFirst) {
      callbacks.onFirst.call(this, ...expandStoreLocal());
    }

    if (ajaxFetchCallbacks.onFirst) {
      ajaxFetchCallbacks.onFirst.forEach((callback) => {
        callback.call(this, ...expandStoreLocal());
      });
    }

    if (callbacks.onError) {
      callbacks.onError.call(this, ...expandStoreLocal());
    }
    privateStore.resolve();
  }).finally(() => {
    privateStore.promise.then(() => {
      if (options.overlay) {
        // Hide loader
        $('#total-overlay').hide();
      }
      if (options.hideModal) {
        // Hide all modals
        $(options.hideModal).modal('hide');
      }
      switch (options.scrollMode) {
        case 0:
          $.doScroll(0);
          break;
        case 1:
          $.doScroll(0, true);
          break;
        default:
          break;
      }
      if (regenerateCSRF) {
        regenerateCSRF();
      }
      if (callbacks.onFinish) {
        callbacks.onFinish.call(this, ...expandStoreLocal());
      }
      if (ajaxFetchCallbacks.onFinish) {
        ajaxFetchCallbacks.onFinish.forEach((callback) => {
          callback.call(this, ...expandStoreLocal());
        });
      }
    });
  });
};

ajaxFetch.defaults = {
  message: 'Error',
  messageTitle: 'Unknown error',
  ...(($.ajaxFetch && $.ajaxFetch.defaults) || {}),
  ...((window.dottom.ajaxFetch && window.dottom.ajaxFetch.defaults) || {}),
};

export default ajaxFetch;
export const setRegenerateCSRF = (regenerateCSRF) => {
  funcRegenerateCSRF = regenerateCSRF;
};
