const $ = require('jquery');

// this bit needs to be loaded on every page where an ajax POST may happen
const csrfData = {};
if ($.cookie('csrf_cookie_name')) {
  csrfData.csrf_test_name = $.cookie('csrf_cookie_name');
}
$.ajaxSetup({
  data: csrfData,
});
