import * as colors from './helpers/colors';
import * as copy from './deep-copy';
import * as easing from './helpers/easing';
import * as formdata from './helpers/formdata';
import * as image from './helpers/image';
import * as plugins from './helpers/plugins';
import * as urls from './helpers/urls';
import Random from './helpers/random';

export default {
  colors,
  copy,
  easing,
  formdata,
  image,
  plugins,
  urls,
  random: (
    seed = -1,
    bigInt1 = 13,
    bigInt2 = 271,
    bigIntMod = 0x5a1addad,
  ) => new Random(seed, bigInt1, bigInt2, bigIntMod),
};
