import convertAccents from './convert-accent';

const XRegExp = require('xregexp');

const emoji = '\u00a9\u00ae\u2000-\u3300\ud83c\ud000-\udfff\ud83d\ud000-\udfff\ud83e\ud000-\udfff';

export default (str, separator = '-', toLower = false, i18n = true) => {
  const patterns = [
    ['&.+?;', ''],
    [`[^${emoji}\\w\\d\\p{L} _-]`, ''],
    ['\\s+', separator],
    [`(${separator})+`, separator],
  ];

  let output = str;

  if (i18n) {
    output = convertAccents(output);
  }

  patterns.forEach((accent) => {
    const [regex, replace] = accent;
    output = output.replace(new XRegExp(regex, 'gu'), replace);
  });
  if (toLower) {
    output = output.toLowerCase();
  }
  return output;
};
