const cmsDefaults = require('dottom@common/defaults');
const $ = require('jquery');

window.dottom = window.dottom || {};

export const addSvg = (icon, attributes, classes, svgClasses, file = null) => {
  const svgFile = file || ($.cmsDefaults && $.cmsDefaults.svgFile)
    || (window.dottom.cmsDefaults && window.dottom.cmsDefaults.svgFile)
    || cmsDefaults.svgFile;
  return `<span${classes
    ? ` class="${classes}"`
    : ''}${attributes
    ? ` ${attributes}`
    : ''}>${!icon && file
    ? `<object${svgClasses
      ? ` class="${svgClasses}"`
      : ''} data="${file}"></object>`
    : `<svg${svgClasses
      ? ` class="${svgClasses}"`
      : ''} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><use xlink:href="${svgFile}#${icon}" href="${svgFile}#${icon}"></use></svg>`}</span>`;
};

export const getSvg = (icon, className) => {
  const svgFile = ($.cmsDefaults && $.cmsDefaults.svgFile)
    || (window.dottom.cmsDefaults && window.dottom.cmsDefaults.svgFile)
    || cmsDefaults.svgFile;
  const xmlns = 'http://www.w3.org/2000/svg';
  const boxWidth = 1000;
  const boxHeight = 1000;
  const svgElement = document.createElementNS(xmlns, 'svg');
  svgElement.setAttributeNS(null, 'viewBox', `0 0 ${boxWidth} ${boxHeight}`);
  svgElement.setAttributeNS(null, 'role', 'img');
  className.split(' ').forEach((classStr) => {
    svgElement.classList.add(classStr);
  });

  const xlink = 'http://www.w3.org/1999/xlink';
  const useElement = document.createElementNS(xmlns, 'use');
  useElement.setAttributeNS(xlink, 'xlink:href', `${svgFile}#${icon}`);
  svgElement.appendChild(useElement);

  return svgElement;
};
