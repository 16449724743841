import $ from 'jquery';
import dynamicLoad from 'dottom@common/dynamic-loading';
import cssLoad from 'dottom@common/css-load';
import CONFIG from 'dottom@common/config';

const defaultPlugins = [
  'source',
  'fontsize',
  'dtfontcolor',
  'alignment',
  'properties',
  'imagepicker',
  'table',
  'filemanager',
  'video',
  'underline',
  'scriptbuttons',
  'specialcharacters',
  'premsEmoji',
  'creativeorangeFontAwesome',
  'globalData',
  'svg',
  'chunkedUploads',
  'messages',
  'dottom',
];

const pluginFilemap = {
  creativeorangeFontAwesome: 'fontawesome',
  premsEmoji: 'emoji',
  globalData: 'globaldata',
  chunkedUploads: 'chunked_uploads',
};

const copyImageCssWidthToAttribute = function copyImageCssWidthToAttribute() {
  const width = +$(this).css('width');
  if (width > 0) {
    $(this).attr('width', width);
  }
};

const uploadStartCallback = function uploadStartCallback() {
  this.progress.show();
};

const syncBeforeCallback = function syncBeforeCallback(html) {
  // set image width as attribute
  const $html = $('<p/>').html(html);
  $html.find('img').each(copyImageCssWidthToAttribute);
  return $html.html();
};

export const initRedactor = (element, props, options = {}) => {
  const {
    csrf,
    lang,
    allowedAttr = null,
    urls = {},
    plugins = null,
    paragraphize = true,
  } = props;

  const {
    callbacks = null,
  } = options;

  const {
    imageUpload = '/admin/upload/image',
    imageList: imageManagerJson = '/admin/upload/get_images',
    fileUpload = '/admin/upload/file_upload',
    fileList: fileManagerJson = '/admin/upload/get_files',
  } = urls;

  const redactorProps = {
    plugins: defaultPlugins,
  };

  if (callbacks) {
    redactorProps.callbacks = callbacks;
  }
  if (plugins) {
    redactorProps.plugins = plugins;
  }

  const $element = $(element);
  $element.redactor({
    lang,
    fileUpload,
    imageUpload,
    imageManagerJson,
    fileManagerJson,
    uploadImageFields: csrf,
    uploadFileFields: csrf,
    imageUploadFields: csrf,
    fileUploadFields: csrf,
    uploadLimit: 2000000,
    imageResizable: true,
    imageTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/svg+xml', 'image/webp'],
    linkEmail: true,
    linkTooltip: true,
    toolbarFixed: false,
    imagePosition: false,
    toolbarFixedTopOffset: 69,
    buttonsHide: ['indent', 'outdent'],
    formatting: ['p', 'blockquote', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    formattingAdd: [],
    replaceTags: {},
    allowedAttr,
    minHeight: 300,
    removeEmpty: ['strong', 'em', 'p', 'span'],
    replaceDivs: false,
    idPrefix: $element.data('id-prefix') || '',
    paragraphize,
    uploadStartCallback,
    syncBeforeCallback,
    ...redactorProps,
  });

  return $element;
};

export const getLoaders = (lang, part = 0, props = {}) => {
  const loader = [];
  const plugins = props.plugins ?? defaultPlugins;

  switch (part) {
    case 1:

      loader.push(dynamicLoad(`/assets/js/editor/lang/${lang}.js`));

      plugins.forEach((plugin) => {
        const file = pluginFilemap[plugin] || plugin;
        loader.push(dynamicLoad(`/assets/js/editor/plugins-2.10/${file}/${file}.js`));
      });
      break;
    case 0:
    default:
      loader.push(cssLoad('/assets/css/editor/fontawesome.css'));
      loader.push(cssLoad('/assets/css/editor/globaldata.css'));
      loader.push(dynamicLoad('/assets/js/editor/redactor-2.10.min.js'));
      loader.push(dynamicLoad(`/assets/js/${CONFIG.LOCATION === 'admin' ? 'admin' : 'common'}/text-editor-langs.js`, () => !!window.dottom?.cmsDefaults?.text?.textEditor));
      if (plugins.includes('globalData')) {
        loader.push(dynamicLoad('/assets/js/admin/globaldata.js'));
      }
      break;
  }
  return loader;
};

const setLoadingOverlay = (textArea, loadingColor, loadingOpacity) => {
  const loadingDiv = document.createElement('div');
  loadingDiv.className = 'block-container';

  textArea.parentNode.insertBefore(loadingDiv, textArea);
  loadingDiv.appendChild(textArea);

  const loadingOverlay = document.createElement('div');
  loadingOverlay.className = 'block-position-overlay col-flex-centered';
  loadingDiv.appendChild(loadingOverlay);

  const loadingBackground = document.createElement('div');
  loadingBackground.className = 'block-position-overlay';
  loadingBackground.style.opacity = loadingOpacity;
  if (loadingColor.startsWith('.')) {
    loadingBackground.classList.add(loadingColor.slice(1));
  } else {
    loadingBackground.style.backgroundColor = loadingColor;
  }
  loadingOverlay.appendChild(loadingBackground);

  const loadingImg = document.createElement('img');
  loadingImg.src = '/assets/images/div/saving.gif';
  loadingImg.className = 'block-container';
  loadingOverlay.appendChild(loadingImg);

  return loadingDiv;
};

const loadTextEditor = (textArea, props = {
  lang: 'en',
}) => {
  const {
    lang = 'en',
    disabled = null,
    loadingColor = '#ffffff',
    loadingOpacity = 0.75,
    options = {},
    ...restProps
  } = props;

  const realProps = {
    lang,
    ...restProps,
  };

  realProps.plugins = [...(realProps.plugins ?? defaultPlugins)];

  if (disabled) {
    realProps.plugins = realProps.plugins.filter((plugin) => !disabled.includes(plugin));
  }

  const loadingDiv = setLoadingOverlay(textArea, loadingColor, loadingOpacity);

  Promise.all(getLoaders(lang, 0, realProps)).then(() => {
    Promise.all(getLoaders(lang, 1, realProps)).then(() => {
      loadingDiv.replaceWith(textArea);
      initRedactor(textArea, realProps, options);
    });
  });
};

export default loadTextEditor;
