const $ = require('jquery');
/* ======================
 * BOOTSTRAP CAROUSEL FIX
 * ======================
 */

const overwriteCarousel = () => {
  // This method was created to get rid of linter errors
  const forceReflow = (element) => element.offsetWidth;

  $.fn.carousel.Constructor.prototype.getTransition = function carouselGetTransition() {
    if (this.slideTime) {
      return this.slideTime;
    }
    if (this.$element.data('delay')) {
      this.slideTime = this.$element.data('delay');
      return this.slideTime;
    }
    this.slideTime = $.fn.carousel.Constructor.TRANSITION_DURATION;
    const $element = this.$element.find('.item.active');
    let slideTime = $element.css('transition-duration');
    if (!slideTime) {
      slideTime = $element[0].style.WebkitTransitionDuration
        || $element[0].style.transitionDuration;
    }
    if (!slideTime) {
      const style = window.getComputedStyle($element[0]);
      slideTime = style.getPropertyValue('transition-duration');
    }
    if (slideTime) {
      if (slideTime.substr(-2) === 'ms') {
        slideTime = slideTime.substr(0, slideTime.length - 2) * 1;
      } else {
        slideTime = slideTime.substr(0, slideTime.length - 1) * 1000;
      }
      this.slideTime = slideTime;
    }
    return this.slideTime;
  };

  $.fn.carousel.Constructor.prototype.slide = function carouselSlide(type, next) {
    const $active = this.$element.find('.item.active');
    let $next = next || this.getItemForDirection(type, $active);
    const isCycling = this.interval;
    const direction = type === 'next' ? 'left' : 'right';
    const fallback = type === 'next' ? 'first' : 'last';
    const that = this;

    if (!$next.length) {
      if (!this.options.wrap) {
        return true;
      }
      $next = this.$element.find('.item')[fallback]();
      if (!$next.length) {
        return true;
      }
    }

    if ($next.hasClass('active')) {
      this.sliding = false;
      return false;
    }

    const relatedTarget = $next[0];
    const slideEvent = $.Event('slide.bs.carousel', {
      relatedTarget,
      direction,
    });
    this.$element.trigger(slideEvent);
    if (slideEvent.isDefaultPrevented()) {
      return true;
    }

    this.sliding = true;

    if (isCycling) {
      this.pause();
    }

    if (this.$indicators.length) {
      this.$indicators.find('.active').removeClass('active');
      const $nextIndicator = $(this.$indicators.children()[this.getItemIndex($next)]);
      if ($nextIndicator) {
        $nextIndicator.addClass('active');
      }
    }

    const slidEvent = $.Event('slid.bs.carousel', { relatedTarget, direction }); // yes, "slid"
    if ($.support.transition && this.$element.hasClass('slide')) {
      $next.addClass(type);
      forceReflow($next[0]); // force reflow
      $active.addClass(direction);
      $next.addClass(direction);
      $active
        .one('bsTransitionEnd', () => {
          $next.removeClass([type, direction].join(' ')).addClass('active');
          $active.removeClass(['active', direction].join(' '));
          that.sliding = false;
          setTimeout(() => {
            that.$element.trigger(slidEvent);
          }, 0);
        })
        .emulateTransitionEnd(this.getTransition());
    } else {
      $active.removeClass('active');
      $next.addClass('active');
      this.sliding = false;
      this.$element.trigger(slidEvent);
    }

    if (isCycling) {
      this.cycle();
    }

    return this;
  };
};

const carouselPromise = new Promise((resolve) => {
  const carouselInterval = setInterval(() => {
    if ($ && $.fn && $.fn.carousel) {
      clearInterval(carouselInterval);
      resolve();
    }
  }, 100);
});

carouselPromise.then(() => {
  overwriteCarousel();
});
