/* eslint-disable import/prefer-default-export */
import CONFIG from 'dottom@common/config';
import jsLoad from 'dottom@common/js-load';

const validPlugins = {
  pages: [
    'lazy-loading',
  ],
  secrets: [
    'maze',
    'slide',
  ],
};

const locationMap = {
  admin: 'cms',
  common: 'common',
  pages: 'pages',
  secrets: 'secrets',
};

const commonLocations = [
  'common',
  'secrets',
];

export const load = (plugin) => {
  let location = CONFIG.LOCATION;
  let pluginFile = plugin;
  if (plugin.indexOf('/') > -1) {
    const pluginSegments = plugin.split('/');
    if (commonLocations.includes(pluginSegments[0])) {
      location = pluginSegments.shift();
      pluginFile = pluginSegments.join('/');
    }
  }
  if (validPlugins[location] && validPlugins[location].includes(pluginFile)) {
    const folder = locationMap[location] ?? location;
    const file = `${folder}/${pluginFile}`;
    return jsLoad(file);
  }
  return Promise.reject();
};
