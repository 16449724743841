/* eslint-disable import/prefer-default-export */
const images = {
  webP: {
    basic: 'data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoCAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==',
    lossless: 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAQAAAAfQ//73v/+BiOh/AAA=',
  },
};

const features = {
  checked: {
    webP: false,
  },
  webP: {
    basic: false,
    lossless: false,
  },
};

export const checkHasWebP = () => new Promise((resolve) => {
  const { checked, webP } = features;
  if (checked.webP) {
    resolve(webP);
  } else {
    const promises = [];

    Object.keys(images.webP).forEach((feature) => {
      const img = new Image();
      img.src = images.webP[feature];

      promises.push(new Promise((imageResolve, imageReject) => {
        img.addEventListener('load', () => {
          if (img.width === 2 && img.height === 1) {
            webP[feature] = true;
            imageResolve();
          }
          imageReject();
        });

        img.addEventListener('error', () => {
          imageReject();
        });
      }));
    });

    Promise.allSettled(promises).then(() => {
      resolve(webP);
    });
  }
});
