import $ from 'jquery';
import jsLoad from 'dottom@common/js-load';

export const setLazyLoadingTabBtns = ($tabBtns = null) => {
  ($tabBtns ?? $('[data-toggle="tab"], [data-toggle="pill"]')).each(function setTabLazyLoading() {
    const $this = $(this);

    if ($this.data('initLazyLoading')) {
      return;
    }

    $this.on('shown.bs.tab', () => {
      $(window).trigger('updateLazyLoading');
    });

    $this.data('initLazyLoading', true);
  });
};

/* ================
 * = LAZY LOADING =
 * ================
 */
const initLazyLoading = ($lazyLoaders = null) => (
  jsLoad('pages/lazy-loading').then(() => {
    ($lazyLoaders ?? $('[data-lazy-load], .lazy-load')).lazyloading();
  })
);

export default initLazyLoading;
