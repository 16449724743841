import loadModule from '../module-load';
import onReady from '../on-ready';

const loadAsyncRuffle = async (url, element = null) => {
  await loadModule('Ruffle', () => !!window.RufflePlayer);
  const ruffle = window.RufflePlayer.newest();
  const player = ruffle.createPlayer();
  player.config.publicPath = '/assets/js/ruffle';
  const container = element ?? document.createElement('div');
  container.className = 'block-container';
  container.style.width = '100%';
  player.style.position = 'absolute';
  player.style.left = '0';
  player.style.top = '0';
  player.style.width = '100%';
  player.style.height = '100%';
  container.appendChild(player);
  const promise = new Promise((resolve) => {
    player.addEventListener('loadedmetadata', () => {
      const { _metadata: metadata } = player;
      container.style.paddingBottom = `${+((metadata.height / metadata.width) * 100).toFixed(4)}%`;
      resolve();
    });
  });
  await player.load(url);
  await promise;
};

const loadRuffle = (url, element = null) => {
  const container = element ?? document.createElement('div');
  loadAsyncRuffle(url, container);
  return container;
};

export const autoloadRuffle = () => {
  onReady(() => {
    const ruffleElements = [...document.querySelectorAll('[data-ruffle]')];

    ruffleElements.forEach(async (ruffleElement) => {
      const url = ruffleElement.dataset.ruffle;
      const loadRuffleElement = async () => {
        const container = document.createElement('div');
        ruffleElement.parentNode.insertBefore(container, ruffleElement);
        await loadAsyncRuffle(url, container);
        ruffleElement.parentNode.removeChild(ruffleElement);
      };

      if (
        ruffleElement instanceof HTMLButtonElement
        || ruffleElement instanceof HTMLAnchorElement
      ) {
        ruffleElement.addEventListener('click', async (e) => {
          e.preventDefault();
          await loadRuffleElement();
        });
      } else {
        await loadRuffleElement();
      }
    });
  });
};

export default loadRuffle;
