const isPlainObject = (obj) => {
  if (!obj || obj.toString() !== '[object Object]') {
    return false;
  }

  const prototype = Object.getPrototypeOf(obj);

  if (!prototype) {
    return true;
  }

  return Object.prototype.hasOwnProperty.call(prototype, 'constructor')
    && typeof prototype.constructor === 'function'
    && prototype.constructor.toString() === Object.toString();
};

const copyObjects = (target = {}, ...args) => {
  const newTarget = target;
  args.forEach((arg) => {
    if (arg === null) {
      return;
    }
    Object.keys(arg).forEach((key) => {
      const src = arg[key];

      if (src === target) {
        return;
      }

      if (typeof src === 'undefined') {
        return;
      }

      if (Array.isArray(src)) {
        // eslint-disable-next-line no-use-before-define
        newTarget[key] = copyArray(src);
      } else if (isPlainObject(src)) {
        newTarget[key] = target[key] && isPlainObject(target[key]) ? target[key] : {};
        copyObjects(target[key], src);
      } else {
        newTarget[key] = src;
      }
    });
  });
  return newTarget;
};

const copyArray = (original) => {
  if (!Array.isArray(original)) {
    return original;
  }
  const copy = [];
  Object.keys(original).forEach((key) => {
    const value = original[key];
    if (Array.isArray(value)) {
      copy[key] = copyArray(value);
    } else if (isPlainObject.value) {
      copy[key] = copyObjects({}, value);
    } else {
      copy[key] = value;
    }
  });
  return copy;
};

export {
  isPlainObject,
  copyObjects,
  copyArray,
};
