const $ = require('jquery');
/* Auto-size carousels */

const overwriteCarousel = () => {
  let $autoHeight = $();
  $('[data-ride="carousel"][data-height]').each(function overwriteCarouselEach() {
    const $this = $(this);
    if ($this.data('height') === 'auto') {
      $autoHeight = $autoHeight.add($this);
    }
  });

  if ($autoHeight.length > 0) {
    $(window).on('resize.carousel-auto-height', $.throttle(() => {
      $autoHeight.each(function overwriteCarouselResizeEach() {
        const $this = $(this);
        const $inner = $this.find('.carousel-inner');
        const $items = $inner.find('.item');

        let maxHeight = 0;
        $items.each(function overwriteCarouselResizeEachItem() {
          $(this).addClass('show');
          maxHeight = Math.max(maxHeight, $(this).outerHeight());
          $(this).removeClass('show');
        });
        $inner.css('min-height', `${maxHeight}px`);
      });
    }, 250));
    $(window).trigger('resize.carousel-auto-height');
  }
};

const carouselPromise = new Promise((resolve) => {
  const carouselInterval = setInterval(() => {
    if ($ && $.fn && $.fn.carousel) {
      clearInterval(carouselInterval);
      resolve();
    }
  }, 100);
});

carouselPromise.then(() => {
  overwriteCarousel();
});
