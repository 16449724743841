import $ from 'jquery';
import ajaxFetch from '../shared/jquery-common/ajax-fetch';
import './css-layers';

const $reloadCaptcha = $('[data-refresh-captcha][data-target]');
if ($reloadCaptcha.length) {
  $reloadCaptcha.each(function reloadCaptcha() {
    const $this = $(this);
    $this.on('click', () => {
      const url = `/${$this.data('refreshCaptcha')}/regenerate_captcha_code`;
      const $target = $($this.data('target'));

      ajaxFetch({
        url,
      }, {
        showMessage: false,
      }, {
        onAfterSuccess: (response) => {
          $target.html(response.content);
        },
      });
    });
  });
}
