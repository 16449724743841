const pdeeList = [];
const keys = [];
const keyPressed = {};
let maxChars = 0;

const setKey = (key) => {
  switch (key.toLowerCase()) {
    case 'up':
      return 'ArrowUp';
    case 'down':
      return 'ArrowDown';
    case 'left':
      return 'ArrowLeft';
    case 'right':
      return 'ArrowRight';
    case 'enter':
    case 'return':
      return 'Enter';
    case 'space':
    case 'spacebar':
      return ' ';
    default:
      return key;
  }
};

class PDEE {
  constructor(keyList, effect) {
    this.keys = [
      ...keyList,
    ];
    maxChars = Math.max(maxChars, keyList.length);
    this.effect = effect;
    if (this.keys && this.keys.length > 0) {
      this.keys = this.keys.map((key) => {
        if (Array.isArray(key)) {
          const realKey = setKey(key.pop());
          const mods = key.map((mod) => {
            switch (mod.toLowerCase()) {
              case 'alt':
                return 'Alt';
              case 'ctrl':
                return 'Control';
              case 'shift':
                return 'Shift';
              default:
                return mod;
            }
          });
          mods.push(realKey);
          return mods;
        }
        return setKey(key);
      });
    }
    pdeeList.push(this);
  }
}

const onKeyDown = (event) => {
  const mods = [];
  if (keyPressed[event.key]) {
    return;
  }
  keyPressed[event.key] = true;
  if (['Alt', 'AltGraph', 'Control', 'Shift'].includes(event.key)) {
    return;
  }
  if (event.getModifierState('Alt')) {
    mods.push('Alt');
  }
  if (event.getModifierState('Ctrl')) {
    mods.push('Ctrl');
  }
  if (event.getModifierState('Shift') && (event.key.length > 1 || event.key === ' ')) {
    mods.push('Shift');
  }
  if (mods.length > 0) {
    keys.push([...mods, event.key]);
  } else {
    keys.push(event.key);
  }
  if (keys.length > maxChars) {
    keys.shift();
  }
  pdeeList.forEach((pdee) => {
    if (keys.length < pdee.keys.length) {
      return false;
    }
    const startIdx = keys.length - pdee.keys.length;
    for (let idx = 0; idx < pdee.keys.length; idx += 1) {
      let current = keys[startIdx + idx];
      let key = pdee.keys[idx];
      if (Array.isArray(key) !== Array.isArray(current)) {
        return false;
      }
      if (Array.isArray(key)) {
        if (key.length !== current.length) {
          return false;
        }
        const check = [...key];
        key = check.pop();
        const cCheck = [...current];
        current = cCheck.pop();
        for (let mIdx = 0; mIdx < check.length; mIdx += 1) {
          if (!cCheck.includes(check[mIdx])) {
            return false;
          }
        }
      }
      if (current !== key) {
        return false;
      }
    }
    pdee.effect();
    return true;
  });
};

const onKeyUp = (event) => {
  keyPressed[event.key] = false;
};

window.addEventListener('keydown', onKeyDown);
window.addEventListener('keyup', onKeyUp);

const createPDEE = (keyList, effect) => (
  new PDEE(!Array.isArray(keyList) ? JSON.parse(atob(keyList)) : keyList, effect)
);

export default createPDEE;
