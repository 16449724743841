import $ from 'jquery';

const regenerateCSRF = require('./regenerate-csrf');

function updatePageGuestbook(html) {
  $('#guestbook_posts_list').html(html);
  // reset form
  $('#guestbookForm').each(function updateEachForm() {
    this.reset();
  });
  $('#total-overlay').hide();
  $('.message_ajax_gb_success').show();
  $('html').scrollTop(0);
}

function postDataGuestbook() {
  // post data
  const message = $('.my_message').val();
  const email = $('#email').val();
  const subject = $('#subject').val();
  const name = $('#name').val();

  // check file upload
  $('#total-overlay').show();
  $('#confirmation_code').val('');

  // validation
  if (message === '') {
    window.captcha_reload();
    $('#total-overlay').hide();
    $('.message_ajax_gb_empty_error').show();
    $('html').scrollTop(0);
    regenerateCSRF();
    return false;
  }
  if (name === '') {
    window.captcha_reload();
    $('#total-overlay').hide();
    $('.message_ajax_gb_empty_error').show();
    $('html').scrollTop(0);
    regenerateCSRF();
    return false;
  }

  regenerateCSRF();

  $.ajax({
    type: 'POST',
    data: {
      subject, email, name, message,
    },
    url: '/pages/ajax_post_controller/ajax_add_guestbook',
    dataType: 'html',
    success(html) {
      updatePageGuestbook(html);
    },
    error() {
    },
    complete() {
      regenerateCSRF();
    },
  });
  return false;
}
window.post_data_guestbook = postDataGuestbook;
