import $ from 'jquery';

// loader and overlay
const $styles = $('link[as="style"][media="only x"]');
if ($styles.length > 0) {
  const loadedStyles = [];
  $styles.each(function deferStyleLoading() {
    const defer = $.Deferred();
    let errorLimit = 5;
    function onLoad() {
      this.removeEventListener('load', onLoad);
      // eslint-disable-next-line no-use-before-define
      this.removeEventListener('error', onError);
      defer.resolve();
    }
    function onError(e) {
      errorLimit -= 1;
      if (this.getAttribute('media') !== 'only x' || errorLimit === 0) {
        onLoad(e);
      }
    }

    this.addEventListener('load', onLoad);
    this.addEventListener('error', onError);
    loadedStyles.push(defer);
  });
  $.when(...loadedStyles).then(() => {
    $('.loading_overlay').hide();
    $('#total-overlay').hide();
  });
} else {
  $('.loading_overlay').hide();
  $('#total-overlay').hide();
}
