const $ = require('jquery');

const cmsDefaults = require('dottom@common/defaults');
const { copyObjects } = require('dottom@common/deep-copy');
const { addSvg } = require('dottom@common/svg');

window.dottom = window.dottom || {};

const createAlert = (options = {}) => {
  const opt = copyObjects({}, window.dottom.createAlert.defaults, options);
  if (!opt.messageTitle) {
    opt.messageTitle = '';
  }
  if (!opt.message) {
    opt.message = '';
  }
  let icon = '';
  if (opt.hasIcon) {
    if (opt.messageIcon) {
      icon = opt.messageIcon;
    } else {
      switch (opt.type) {
        case 'message':
          icon = 'question-circle';
          break;
        case 'info':
          icon = 'question-circle';
          break;
        case 'success':
          icon = 'thumbs-up';
          break;
        case 'warning':
          icon = 'warning';
          break;
        case 'error':
          icon = 'cross';
          break;
        default:
          icon = 'ellipses';
          break;
      }
    }
  }
  let html = opt.template;
  html = html.replace(/\{\{\s*type\s*\}\}/, opt.type);
  html = html.replace(/\{\{\s*icon\s*\}\}/, addSvg(icon, [], '', '', opt.svgFile));
  html = html.replace(/\{\{\s*title\s*\}\}/, opt.messageTitle);
  html = html.replace(/\{\{\s*message\s*\}\}/, opt.message);
  html = html.replace(/\{\{\s*buttons\s*\}\}/, '<slot id="buttons" />');
  const $alertbox = $(html);
  const $buttons = $alertbox.find('#buttons');
  opt.buttons.forEach((button) => {
    const $button = $(`<button type="button" class="alertbox-button">${button.label}</button>`);
    $button.data('action', button.action);
    $button.insertBefore($buttons);
  });
  $buttons.remove();
  $(`#alert-${opt.position}`).append($alertbox);
  $alertbox.alertbox(opt);
};

const alertboxDefaults = {
  autoHide: true,
  hasIcon: true,
  type: 'message',
  message: '',
  messageTitle: '',
  position: 'footer',
  buttons: [],
  svgFile: cmsDefaults.svgFile,
  ...(($.createAlert && $.createAlert.defaults) || {}),
  ...((window.dottom.createAlert && window.dottom.createAlert.defaults) || {}),
};

if (!alertboxDefaults.template) {
  alertboxDefaults.template = `
  <div class="alertbox alertbox-{{type}}">
    <div class="alertbox-wrapper">
      <div class="alertbox-content">
        <button type="button" data-dismiss class="alertbox-dismiss">${addSvg('times', [], '', '', alertboxDefaults.svgFile)}</button>
        <div class="alertbox-icon">{{icon}}</div>
        <div class="alertbox-body">
          <div class="alertbox-title">{{title}}</div>
          <div class="alertbox-message">{{message}}</div>
          <div class="alertbox-buttons">{{buttons}}</div>
        </div>
      </div>
    </div>
  </div>
  `;
}

$.fn.alertbox = function createAlertBox(options) {
  const opt = $.extend({}, $.createAlert.defaults, options);
  $(this).each(function createAlertBoxItt() {
    const $alertbox = $(this);
    $alertbox.addClass('in');
    const height = $alertbox.outerHeight();
    $alertbox.outerHeight(0);
    let alertboxTimeout;

    const removeAlertbox = () => {
      if (alertboxTimeout) {
        clearTimeout(alertboxTimeout);
      }
      $alertbox.animate({
        height: 0,
      }, 500, () => {
        $alertbox.remove();
      });
    };

    const methods = {
      dismiss: removeAlertbox,
    };

    const $buttons = $alertbox.find('.alertbox-button');

    $buttons.each(function createAlertBoxBtn() {
      if ($(this).data('action')) {
        $(this).on('click', $(this).data('action').bind($alertbox, methods));
      }
    });

    const dismissAlertbox = function dismissAlertBox() {
      if (opt.onDismiss) {
        opt.onDismiss.call(this, methods);
      } else {
        removeAlertbox();
      }
    };

    $alertbox.find('[data-dismiss]').on('click', dismissAlertbox);

    $alertbox.animate({
      height,
    }, 500, () => {
      $alertbox.css('height', '');
      if (opt.autoHide) {
        alertboxTimeout = setTimeout(removeAlertbox, opt.autoHide !== true ? opt.autoHide : 10000);
      }
    });
  });
};

const displayAlert = (message, options = {}) => {
  createAlert({
    ...options,
    type: message.message_type,
    message: message.message,
    messageTitle: message.message_title,
    messageIcon: message.message_icon,
  });
};

createAlert.defaults = alertboxDefaults;

export default createAlert;
export {
  displayAlert,
};
