class Random {
  #seed;

  #bigInt1;

  #bigInt2;

  #bigIntMod;

  constructor(seed = -1, bigInt1 = 13, bigInt2 = 271, bigIntMod = 0x5a1addad) {
    this.#bigInt1 = bigInt1 > 2 ? bigInt1 : 13;
    this.#bigInt2 = bigInt2 > 2 ? bigInt2 : 271;
    this.#bigIntMod = bigIntMod;

    this.setSeed(seed > -1 ? seed : (new Date()).getTime());
  }

  setSeed(seed) {
    this.#seed = seed;
  }

  getSeed() {
    return this.#seed;
  }

  next() {
    this.#seed = (this.#seed * this.#bigInt1 + this.#bigInt2) % this.#bigIntMod;
  }

  nextInt(num1 = null, num2 = null) {
    this.next();
    if (num1 === null) {
      return this.getSeed();
    }

    const min = Math.min(num1, num2 ?? 0);
    const max = Math.max(num1, num2 ?? 0);

    return (this.getSeed() % (max - min)) + min;
  }

  nextRandom() {
    this.next();
    return this.getSeed() / (this.#bigIntMod - 1);
  }
}

export default Random;
