/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
import $ from 'jquery';

export default () => {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://embed.twitch.tv/embed/v1.js';

  let timeout;

  const initTwitch = () => {
    const $twitch = $('[data-twitch-embed]');

    $twitch.each(function initTwitchElement() {
      const $this = $(this);
      const embed = $this.data('twitchEmbed').split(':');
      const options = {};

      switch (embed[0]) {
        case 'channel':
          options.channel = embed[1];
          break;
        case 'video':
          options.video = embed[1];
          break;
        case 'collection':
          options.collection = embed[1];
          if (embed.length > 1) {
            options.video = embed[2];
          }
          break;
        default:
          options.video = embed[0];
          break;
      }

      options.width = $this.data('twitchWidth') || '100%';
      options.height = $this.data('twitchHeight') || 480;
      options.allowfullscreen = $this.data('twitchAllowfullscreen') || true;
      options.autoplay = $this.data('twitchAutoplay') || true;
      options.muted = $this.data('twitchMuted') || false;
      options.theme = $this.data('twitchTheme') || 'dark';
      options.time = $this.data('twitchTime') || '0h0m0s';

      if ($this.data('twitchLayout')) {
        options.layout = $this.data('twitchLayout');
      }
      if ($this.data('twitchParent')) {
        options.parent = $this.data('twitchParent').split(/\s*,\s*/);
      }

      const twitchEmbed = new window.Twitch.Embed(this, options);

      $(this).data('twitch', twitchEmbed);
    });
  };

  const onScriptComplete = () => {
    script.removeEventListener('load', onScriptSuccess);
    script.removeEventListener('error', onScriptError);
    clearTimeout(timeout);
  };

  const onScriptSuccess = () => {
    initTwitch();
    onScriptComplete();
  };

  const onScriptError = () => {
    onScriptComplete();
  };

  script.addEventListener('load', onScriptSuccess);
  script.addEventListener('error', onScriptError);
  timeout = setTimeout(onScriptError, 120000);

  head.appendChild(script);
};
