// import dynamicLoad from 'dottom@common/dynamic-loading';
import cssLoad from 'dottom@common/css-load';
import jsLoad from 'dottom@common/js-load';
import baseFile from 'dottom@common/basefile';

// RECRAS
if (document.querySelector('[data-recras]')) {
  const loader = [
    cssLoad(baseFile('pikaday', 'css', false)),
    // dynamicLoad('/assets/js/services/recras/onlinebooking.min.js', () => !!window.RecrasOptions),
  ];
  Promise.all(loader).then(() => {
    jsLoad('pages/services/recras');
  });
}
