/* eslint-disable global-require */

// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
  require('raf').polyfill(global);
}

// Includes polyfill
if (!Array.prototype.includes) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.includes = function includesPolyfill(needle, fromIndex) {
    return this.indexOf(needle, fromIndex) > -1;
  };
}
if (window.NodeList && !NodeList.prototype.forEach) {
  // eslint-disable-next-line no-extend-native
  NodeList.prototype.forEach = function forEachPolyfill(callback, thisArg = window) {
    for (let i = 0; i < this.length; i += 1) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

require('./polyfill/string-prototype-repeat');
require('./polyfill/dom-elements');
