import $ from 'jquery';
import baseFile from 'dottom@common/basefile';
import dynamicLoad from 'dottom@common/dynamic-loading';
import jsLoad from 'dottom@common/js-load';
import cssLoad from 'dottom@common/css-load';
import before from './helper/before-styles';
import twitch from './twitch';
import initLazyLoading, { setLazyLoadingTabBtns } from './lazy-loading';
import './services';
import bsLoaded from '../common/bs-loaded';

window.dottom = window.dottom || {};
const { dottom } = window;

const cssLoadBefore = (cssPath) => cssLoad(cssPath, before());

/* ====================
 * = ANIMATE ON SCROLL=
 * ====================
 */
if ($('[data-aos]').length > 0) {
  const loader = [
    bsLoaded(),
    cssLoadBefore('/assets/css/aos/aos.css'),
    dynamicLoad('/assets/js/aos/aos.js', () => !!window.AOS),
  ];
  Promise.all(loader).then(() => {
    window.AOS.init();
  });
}

/* ==========
 * = BANNER =
 * ==========
 */
// sequence banner (legacy)
if ($('#sequence').length) {
  const loader = [
    dynamicLoad('/assets/js/sequence/sequence.js'),
  ];
  Promise.all(loader).then(() => {
    jsLoad('pages/sequence-slider-v0');
  });
}

if ($('[data-sequence]').length) {
  const loader = [
    cssLoadBefore('/assets/css/sequence/sequence_default.css'),
    dynamicLoad('/assets/js/imagesloaded.pkgd.min.js'),
    dynamicLoad('/assets/js/sequence/sequence-2.1.0.min.js'),
  ];
  Promise.all(loader).then(() => {
    jsLoad('pages/sequence-slider-v1');
  });
}

if (document.querySelectorAll('[data-image-slider]').length) {
  jsLoad('pages/slider');
}

/* ========
 * = FORM =
 * ========
 */
if ($('form.contact_form').length > 0) {
  jsLoad('pages/form');
}

/* =============
 * = GUESTBOOK =
 * =============
 */
if ($('#guestbook_posts_list').length) {
  jsLoad('pages/guestbook');
}

/* =============
 * = HASH TABS =
 * =============
 */
if ($('.hash-switch').length > 0) {
  jsLoad('pages/hash-tab');
}

/* ================
 * = LAZY LOADING =
 * ================
 */
const $lazyLoaders = $('[data-lazy-load], .lazy-load');

if ($lazyLoaders.length > 0) {
  initLazyLoading().then(() => {
    $('[data-group="lazy-load-tab-btns"]').each(function llTabBtnGroups() {
      setLazyLoadingTabBtns($(this).find('[data-toggle="tab"], [data-toggle="pill"]'));
    });
  });
}

/* ===============
 * = PROGRESSBAR =
 * ===============
 */
if ($('[data-progress]').length > 0) {
  dynamicLoad('/assets/js/progressbar/progressbar.min.js').then(() => {
    jsLoad('pages/progress-bar-loader');
  });
}

/* ==================
 * = SLICK CAROUSEL =
 * ==================
 */
if ($('[data-ride="slick-carousel"]').length > 0) {
  const loader = [
    cssLoadBefore('/assets/css/slick/slick.css'),
    dynamicLoad('/assets/js/slick/slick.min.js'),
  ];
  Promise.all(loader).then(() => {
    jsLoad('pages/slick-init');
  });
}

/* ===========
 * = GALLERY =
 * ===========
 */
// Image gallery
if ($('.front_img_gallery').length) {
  jsLoad('pages/gallery');
}

/* =======================
 * = WEBSHOP ADD TO CART =
 * =======================
 */
if ($('[data-webshop-product]').length) {
  jsLoad('pages/page-webshop');
}
dottom.webshop = dottom.webshop || {};
dottom.webshop.initButtons = jsLoad.bind(this, 'pages/page-webshop');

/* ==============
 * = PARALLAXES =
 * ==============
 */
if ($('[data-parallax]').length) {
  jsLoad('pages/parallax');
}

/* ==================
 * = SCROLL SECTION =
 * ==================
 */

if ($('[data-scroll-section]').length) {
  jsLoad('pages/scrollsection');
}

/* ===============
 * = GOOGLE MAPS =
 * ===============
 */
if ($('[data-google-maps]').length > 0) {
  jsLoad('pages/gmaps');
}

/* =================
 * = DEMO ELEMENTS =
 * =================
 */

if ($('.demo').length > 0) {
  jsLoad('pages/demo-elements');
}

/* ==========
 * = TWITCH =
 * ==========
 */

if ($('[data-twitch-embed]').length > 0) {
  twitch();
}

/* ===============
 * = Auto Iframe =
 * ===============
 */

if ($('[data-auto-iframe]').length > 0) {
  cssLoadBefore(baseFile('services/zorgkaartnederland-iframe', 'css', false));
  jsLoad('pages/auto-iframe');
}
