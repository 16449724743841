const bsLoaded = () => (new Promise((resolve) => {
  const stylesheet = document.querySelector('link[rel="stylesheet"][href*="bootstrap"][href$=".css"]');
  if (!stylesheet) {
    resolve();
  }
  const onLoad = () => {
    resolve();
  };

  stylesheet.addEventListener('load', onLoad);
  stylesheet.addEventListener('cssLoaded', onLoad);

  const loadInterval = setInterval(() => {
    const resolvedHref = stylesheet.href;
    const styleSheets = [...document.styleSheets];
    styleSheets.every((sheet) => {
      if (sheet.href === resolvedHref) {
        stylesheet.dispatchEvent(new CustomEvent('cssLoaded'));
        clearInterval(loadInterval);
        return false;
      }
      return true;
    });
  });
}));

export default bsLoaded;
