import TwoWayIframeChild from 'dottom@common/twi/twi-child';
import TwoWayIframeParent from 'dottom@common/twi/twi-parent';
import events from 'dottom@common/twi/twi-events';

require('dottom@common/get-week');

window.dottom = window.dottom || {};

const TemplateNode = require('./template-interpreter');

window.dottom.templateInterpreter = {
  TemplateNode,
};

window.dottom.twi = {
  TwoWayIframeChild,
  TwoWayIframeParent,
  events,
};
