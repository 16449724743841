import onReady from 'dottom@common/on-ready';
import MessageHandler from './message-handler';
import events from './twi-events';

export default class TwoWayIframeChild {
  constructor() {
    this.sourceURL = document.referrer;
    this.messageHandler = new MessageHandler();
    onReady(() => {
      this.messageHandler.initializeEventHandler();
      this.sendMessage({
        event: events.TWI_CHILD_LOADED,
      });
    });
  }

  sendMessage(event) {
    if (!this.sourceURL) {
      return;
    }
    window.parent.postMessage(event, this.sourceURL);
  }

  addMessageHandler(event, callback) {
    this.messageHandler.addMessageHandler(event, callback);
  }

  getMessageHandler(event) {
    return this.messageHandler.getMessageHandler(event);
  }
}
