/**
 * A JavaScript implementation of the template interpreter.
 */

class TemplateNode {
  constructor(template = false, name = '', parent = null) {
    this.nodes = [];
    this.blocks = {};
    this.varFuncs = {};
    this.blockFuncs = {};
    this.blockVisibility = {};
    this.parent = null;
    this.name = '';

    if (template !== false) {
      this.interpret(template, name, parent);
    }
  }

  interpret(template, name = '', parent = null) {
    // Set the name and parent
    this.parent = parent;
    this.name = name;

    // We need to separate the template into arrays, to simplify interpreting.
    const interpreted = this.interpretTemplate(template);
    const { parts, blockParts } = interpreted;

    let blockPartsIdx = 0;
    for (let idx = 0; idx < parts.length; idx += 1) {
      if (blockPartsIdx >= blockParts.length
        || idx < blockParts[blockPartsIdx][0]) {
        this.nodes.push(this.createFlatNode(parts[idx]));
      } else {
        const offsets = blockParts[blockPartsIdx];
        const block = this.createNode(parts, idx, offsets);
        this.nodes.push(block);
        blockPartsIdx += 1;
        idx += offsets[1] - offsets[0];
      }
    }
  }

  interpretTemplate(template) {
    const partitioned = this.partitionTemplate(template);
    const { parts, blocks } = partitioned;
    const blockParts = this.interpretBlocks(blocks);

    return {
      parts,
      blockParts,
    };
  }

  partitionTemplate(template) {
    if (!Array.isArray(template)) {
      return this.partitionTemplateString(template);
    }
    return this.partitionTemplateArray(template);
  }

  partitionTemplateString(template) {
    const parts = [];
    const blocks = [];
    let lastIndex = 0;
    const regex = /{{\s*(.*?)\s*}}/g;
    let match = regex.exec(template);
    while (match !== null) {
      const blockIndex = match.index;
      const block = {
        parsed: match[1].split(':'),
        raw: match[0],
      };
      parts.push(template.slice(lastIndex, blockIndex));
      parts.push(block);
      blocks.push(block);
      // eslint-disable-next-line prefer-destructuring
      lastIndex = regex.lastIndex;
      match = regex.exec(template);
    }
    parts.push(template.slice(lastIndex));
    return {
      parts,
      blocks,
    };
  }

  partitionTemplateArray(template) {
    const parts = [];
    const blocks = [];
    template.forEach((part) => {
      parts.push(part);
      if (typeof part === 'object' && part !== null) {
        blocks.push(part);
      }
    });
    return {
      parts,
      blocks,
    };
  }

  interpretBlocks(blocks) {
    const blockRoot = [];
    const blockParts = [];
    for (let idx = blocks.length - 1; idx >= 0; idx -= 1) {
      const block = blocks[idx].parsed;
      // If the current block begins with /, it's a closing tag
      if (block[0].slice(0, 1) === '/') {
        // Store the index, plus the block name
        blockRoot.push([idx, block[0].slice(1)]);
        // We'll only run this if blockRoot isn't empty
      } else if (blockRoot.length) {
        /* If the current block matches the last block pushed to
         * blockRoot, we can be certain that the entire block has been
         * closed.
        */
        if (block[0] === blockRoot.slice(-1)[0][1]) {
          const currentBlockRoot = blockRoot.pop();
          /* The following two indices refer to the indices where the
           * blocks are supposed to be in the parts variable (see
           * partitionTemplate).
           */
          const startIndex = 1 + idx * 2;
          const endIndex = 1 + currentBlockRoot[0] * 2;
          if (!blockRoot.length) {
            blockParts.unshift([startIndex, endIndex]);
          }
        }
      }
    }
    return blockParts;
  }

  /**
   * This creates a flat node from a part.
   * A flat node is basically a node that doesn't have content that needs to
   * be parsed.
   * @param {Array|String} part An array or a string.
   * @returns {Object}
   * An object containing the node, which can either be var or raw.
   */
  createFlatNode(part) {
    // If this is an object, it's most definitely a var
    if (typeof part === 'object') {
      const block = {
        type: 'var',
        name: part.parsed[0].trim(),
        params: [],
        raw: part.raw,
      };
      // If the array consists of more than one item, set the rest as
      // parameters
      if (part.parsed.length > 1) {
        block.params = part.parsed.slice(1);
      }
      return block;
    }
    // It's a raw node
    return {
      type: 'raw',
      content: part,
    };
  }

  createNode(parts, idx, offsets) {
    // First we need to extract the parts that are between the opening and
    // closing tags, so that we have the content of the node
    const blockPart = parts.slice(offsets[0] + 1, offsets[1]);

    // We'll need to determine if this is a block or a blockvar. Functionally,
    // there are little differences, and this is mostly for compatibility reasons.
    const part = parts[idx].parsed;
    const isDefinedBlock = part[0] === 'block' && part.length > 1;
    const block = {
      type: (isDefinedBlock ? 'block' : 'blockvar'),
      name: part[isDefinedBlock ? 1 : 0].trim(),
      params: [],
    };
    block.content = new TemplateNode(blockPart, block.name, this);
    // We'll need to set the parameters.
    if (part.length > 1) {
      if (part[0] !== 'block') {
        block.params = part.slice(1);
      } else if (part.length > 2) {
        block.params = part.slice(2);
      }
    }
    // Let's add raw mode as well.
    block.raw = [parts[idx].raw, parts[offsets[1]].raw];
    // If this is a block (not a blockvar), we'll need to add this to the
    // array of blocks
    if (isDefinedBlock) {
      this.blocks[block.name] = this.blocks[block.name] || [];
      this.blocks[block.name].push(block);
    }
    return block;
  }

  /**
   * Outputs the current node as an array.
   *
   * Regular text nodes, or raw nodes, will just get placed in as a string. If
   * it's an empty string, it will get ignored, to optimize the output.
   *
   * Variable nodes will get output as an associative array with type, name and
   * params as keys. Block and variable block nodes will get output as associative
   * arrays with type, name, params and content as keys, where content contains
   * a toObject() generated array.
   *
   * @returns {Array|TemplateNode.toObject.returnObject} The current node as an array
   */
  toObject() {
    const returnObject = [];
    this.nodes.forEach((node) => {
      switch (node.type) {
        case 'block':
          returnObject.push({
            type: 'block',
            name: node.name,
            params: node.params,
            content: node.content.toObject(),
          });
          break;
        case 'var':
          returnObject.push({
            type: 'variable',
            name: node.name,
            params: node.params,
          });
          break;
        case 'blockvar':
          returnObject.push({
            type: 'variable_block',
            name: node.name,
            params: node.params,
            content: node.content.toObject(),
          });
          break;
        case 'raw':
        default:
          if (node.content) {
            returnObject.push(node.content);
          }
          break;
      }
    });
    return returnObject;
  }

  parse(data = [], opt = []) {
    let output = '';
    const globalVariables = {
      global: {},
    };
    let raw = opt.raw || false;

    // Determines how variable tags should be treated
    switch (raw) {
      case 'full':
        raw = 1;
        break;
      case 'unset':
        raw = 2;
        break;
      case 'undefined':
        raw = 3;
        break;
      default:
        raw = (raw === true ? 1 : 0);
        break;
    }

    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (key === 'global') {
        globalVariables.global = {
          ...value,
          ...globalVariables.global,
        };
      } else if (key.startsWith('global:')) {
        globalVariables.global[key.slice(7)] = value;
      } else if (key.startsWith('block:')) {
        globalVariables.global[key] = value;
      }
    });

    this.nodes.forEach((node) => {
      switch (node.type) {
        case 'block': {
          const value = {
            ...globalVariables,
            ...(data[`block:${node.name}`] ?? {}),
          };
          if (raw === 1) {
            output = `${output}${node.raw[0]}${node.content.parse(value, opt)}${node.raw[1]}`;
          } else if (this.blockVisibility[node.name]) {
            const visibility = this.blockVisibility[node.name];
            if (
              typeof visibility !== 'function'
              || this.blockVisibility[node.name](this, node, value, globalVariables, data)
            ) {
              const func = this.blockFuncs[node.name];
              if (func) {
                output = `${output}${func(this, node, value, globalVariables, data, opt)}`;
              } else {
                output = `${output}${node.content.parse(value, opt)}`;
              }
            }
          }
          break;
        }
        case 'var': {
          const value = data[node.name] ?? '';
          const func = this.getVarFunc(node.name);

          if (raw === 1
            || (raw === 2 && !data[node.name] && !func)
            || (raw === 3 && typeof data[node.name] === 'undefined' && !func)) {
            output = `${output}${node.raw}`;
          } else if (func) {
            output = `${output}${func(this, node, value, globalVariables, data, opt)}`;
          } else {
            output = `${output}${value}`;
          }
          break;
        }
        case 'blockvar': {
          const func = this.getVarFunc(node.name);
          let value = data[node.name] ?? {};

          if (raw === 1
            || (raw === 2 && !data[node.name] && !func)
            || (raw === 3 && typeof data[node.name] === 'undefined' && !func)) {
            if (typeof value === 'object' && value !== null) {
              value = {
                ...globalVariables,
                ...value,
              };
            } else {
              value = { ...globalVariables };
            }
            output = `${output}${node.raw[0]}${node.content.parse(value, opt)}${node.raw[1]}`;
          } else if (func) {
            value = value ?? '';
            output = `${output}${func(this, node, value, globalVariables, data, opt)}`;
          } else if (typeof value === 'object' && value !== null) {
            value = {
              ...globalVariables,
              ...value,
            };
            output = `${output}${node.content.parse(value, opt)}`;
          } else if (Array.isArray(value)) {
            value.forEach((val) => {
              output = `${output}${node.content.parse({
                ...globalVariables,
                ...(val ?? {}),
              }, opt)}`;
            });
          } else {
            output = `${output}${node.content.parse(globalVariables, opt)}`;
          }
          break;
        }
        case 'raw':
        default:
          output = `${output}${node.content}`;
          break;
      }
    });

    return output;
  }

  getVarFunc(funcName) {
    if (this.varFuncs[funcName]) {
      return this.varFuncs[funcName];
    }
    if (this.parent) {
      return this.parent.getVarFunc(funcName);
    }

    return null;
  }

  addNodeVarData(block, selector, data, type) {
    const vars = this.getVar(block, false, true);
    vars.forEach((variable) => {
      if (variable.content) {
        variable.content.addNodeData(selector, data, type);
      }
    });
  }

  addNodeDeepData(selector, data, type) {
    Object.values(this.blocks).forEach((blockList) => {
      blockList.forEach((blockNode) => {
        blockNode.content.addNodeData(selector, data, type);
      });
    });
  }

  addNodeData(nodeName, data, type) {
    const nodeNames = nodeName.split('|');
    if (nodeNames.length > 1) {
      nodeNames.forEach((nodeNameItem) => {
        this.addNodeData(nodeNameItem, data, type);
      });
      return;
    }
    const tree = nodeName.split(':');
    if (tree.length === 1) {
      this[type][nodeName] = data;
    } else {
      const selectorData = this.createNewSelector(tree);
      const { block, selector } = selectorData;
      if (block.startsWith('$')) {
        const realBlock = block.slice(1);
        this.addNodeVarData(realBlock, selector, data, type);
      } else if (block === '*$') {
        this.addNodeVarData('*', selector, data, type);
        this.addNodeDeepData(selector, data, type);
      } else if (block === '*') {
        this.addNodeDeepData(selector, data, type);
      } else if (typeof this.blocks[block] !== 'undefined') {
        this.blocks[block].forEach((blockNode) => {
          blockNode.content.addNodeData(selector, data, type);
        });
      }
    }
  }

  createNewSelector(tree) {
    let block = tree.shift();
    let selector = tree.join(':');
    if (block.slice(0, 1) === '>') {
      tree.unshift(block);
      selector = `${selector}|${tree.join(':')}`;
      block = block.slice(1);
    }
    return {
      selector,
      block,
    };
  }

  addVariableFunction(variable, func) {
    this.addNodeData(variable, func, 'varFuncs');
  }

  addBlockFunction(variable, func) {
    this.addNodeData(variable, func, 'blockFuncs');
  }

  addBlockVisibility(variable, visibility) {
    this.addNodeData(variable, visibility, 'blockVisibility');
  }

  getBlock(blockName, all = false) {
    if (this.blocks[blockName]) {
      return all ? this.blocks[blockName] : this.blocks[blockName][0];
    }
    if (this.parent) {
      const block = this.parent.getBlock(blockName, all);
      return block;
    }
    return null;
  }

  getBlocksStart(blockName, blocks = []) {
    this.nodes.forEach((node) => {
      if (node.type !== 'block') {
        return;
      }
      const key = node.name;
      if (key.startsWith(blockName)) {
        blocks.push(node);
      }
    });

    if (this.parent) {
      this.parent.getBlocksStart(blockName, blocks);
    }
    return blocks;
  }

  getVar(varname, deep = false, all = false, startsWith = false) {
    let vars = false;
    if (all) {
      vars = [];
    }

    for (let idx = 0; idx < this.nodes.length; idx += 1) {
      const node = this.nodes[idx];

      switch (node.type) {
        case 'var':
          if (varname === '*' || node.name === varname || (startsWith && node.name.startsWith(startsWith))) {
            if (all) {
              vars.push(node);
            } else {
              return node;
            }
          }
          break;
        case 'blockvar':
          if (varname === '*' || node.name === varname || (startsWith && node.name.startsWith(startsWith))) {
            if (all) {
              vars.push(node);
            } else {
              return node;
            }
          }
          if (deep) {
            const nodeVars = node.content.getVar(varname, deep, all, startsWith);
            if (nodeVars) {
              if (all) {
                vars.push(...nodeVars);
              } else {
                return nodeVars;
              }
            }
          }
          break;
        case 'block':
          if (deep) {
            const nodeVars = node.content.getVar(varname, deep, all, startsWith);
            if (nodeVars) {
              if (all) {
                vars.push(...nodeVars);
              } else {
                return nodeVars;
              }
            }
          }
          break;
        default:
          break;
      }
    }

    return vars;
  }
}

module.exports = TemplateNode;
