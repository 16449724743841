import $ from 'jquery';

/* ====================
 * = ANCHOR SCROLLING =
 * ====================
 */
$('body').on('click', 'a.scroll-to[href^="#"]', function onScrollTo(e) {
  const $elem = $($(this).attr('href'));
  let speed = 500;
  if ($(this).data('scroll-to-speed') && !Number.isNaN(+$(this).data('scroll-to-speed'))) {
    speed = $(this).data('scroll-to-speed');
  }
  if ($elem.length > 0) {
    e.preventDefault();
    const { top } = $elem.offset();
    $('html, body').animate({ scrollTop: top }, speed, 'swing');
  }
});
