export default class MissingDataException extends Error {
  constructor(message) {
    super(message);
    this.name = 'MissingDataException';
    this.message = message ? `The following data has not been provided: ${message}` : 'The data provided was insufficient';
    // Use V8's native method if available, otherwise fallback
    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, MissingDataException);
    } else {
      this.stack = (new Error()).stack;
    }
  }
}
