import $ from 'jquery';
import dynamicLoad from 'dottom@common/dynamic-loading';
import jsLoad from 'dottom@common/js-load';
import cssLoad from 'dottom@common/css-load';

if ($('[data-input="filepicker"], [data-input="dropzone"]').length > 0) {
  jsLoad('common/filepicker');
}

if ($('[data-lightbox]').length > 0) {
  cssLoad('/assets/css/ekko-lightbox/ekko-lightbox.css');
  cssLoad('/assets/css/ekko-lightbox/ekko-lightbox-theme.css');
  dynamicLoad('/assets/js/ekko-lightbox/ekko-lightbox.min.js');
  jsLoad('common/lightbox');
}

if ($('[data-media-controller]').length) {
  jsLoad('common/media-controller');
}
