import onReady from 'dottom@common/on-ready';

window.dottom = window.dottom || {};
const CMS_EVENTS = require('./shared/events');
const regenerateCSRF = require('./advanced/regenerate-csrf');

require('eventsource');
require('./shared/dottom-shared');

require('./shared/jquery-common');

window.dottom.createAlert.defaults.position = 'page';

window.regenerate_csrf = regenerateCSRF;
window.dottom.regenerateCSRF = regenerateCSRF;

const init = () => {
  document.removeEventListener('DOMContentLoaded', init);
  window.removeEventListener('load', init);

  /* eslint-disable global-require */
  require('./shared/post-pageload');

  require('dottom@advanced/code-split');
  require('dottom@advanced/guestbook');
  require('dottom@advanced/anchor-scrolling');
  require('dottom@advanced/overlay-deferred-css');
  require('./advanced/dropdown-menu');
  require('./advanced/webshop');

  require('./advanced/advanced-loaded');
  require('./advanced/advanced-old');
  /* eslint-enable global-require */

  CMS_EVENTS.dispatch(CMS_EVENTS.EVENTS.CMS_INIT);
};

onReady(init);
