import $ from 'jquery';

const images = {
  detect: link => link && `${link}`.match(/(^data:image\/.*,)|(\.(jp(e|g|eg)|gif|png|bmp|webp|svg)((\?|#).*)?$)/i),
  beforeRender: (link, resolve = null, reject = null) => {
    const image = new Image();
    image.src = link;
    image.addEventListener('load', () => {
      resolve(image);
    });

    image.addEventListener('error', () => {
      reject();
    });
  },
  afterRender: (data, $modal) => {
    const $content = $modal.find('.modal-body');
    const $image = $(data);
    $content.append($image);
    images.onResize.call($modal);
  },
  onResize() {
    const $modal = $(this);
    const $dialog = $modal.find('.modal-dialog');
    const $content = $modal.find('.modal-body');
    const $image = $content.find('img');

    $image.width(0);
    $image.height(0);

    const dialogSize = {
      width: $dialog.width(),
      height: $dialog.height(),
    };
    const windowSize = {
      width: $(window).width() - dialogSize.width,
      height: $(window).height() - dialogSize.height,
    };

    // Reset image size, and retrieve them
    $image.css('width', '');
    $image.css('height', '');
    const imageSize = {
      width: $image.width(),
      height: $image.height(),
    };

    const isWidth = windowSize.width < imageSize.width;
    const isHeight = windowSize.height < imageSize.height;

    let newWidth;
    let newHeight;
    if (isWidth && isHeight) {
      const windowRatio = windowSize.width / windowSize.height;
      const imageRatio = imageSize.width / imageSize.height;

      if (imageRatio < windowRatio) {
        newHeight = windowSize.height;
        newWidth = (imageSize.width / imageSize.height) * newHeight;
      } else {
        newWidth = windowSize.width;
        newHeight = (imageSize.height / imageSize.width) * newWidth;
      }
    } else if (isWidth) {
      newWidth = windowSize.width;
      newHeight = (imageSize.height / imageSize.width) * newWidth;
    } else if (isHeight) {
      newHeight = windowSize.height;
      newWidth = (imageSize.width / imageSize.height) * newHeight;
    }
    $image.width(newWidth);
    $image.height(newHeight);
  },
};

export default images;
