const CMSInit = new CustomEvent('CMSInit');
const CMSReload = new CustomEvent('CMSReload');
const CMSPageLoad = new CustomEvent('CMSPageLoad');
const CMSPageUnload = new CustomEvent('CMSPageUnload');

const events = {
  CMSInit: () => CMSInit,
  CMSReload: () => CMSReload,
  CMSPageLoad: () => CMSPageLoad,
  CMSPageUnload: () => CMSPageUnload,
};

const eventList = {
  CMS_INIT: 'CMSInit',
  CMS_RELOAD: 'CMSReload',
  CMS_PAGE_LOAD: 'CMSPageLoad',
  CMS_PAGE_UNLOAD: 'CMSPageUnload',
};

export const createEvent = (eventName, payload = null) => (
  new CustomEvent(eventName, payload ? {
    detail: payload,
  } : null)
);

export const dispatch = (event, payload = null) => {
  const eventObj = payload
    ? createEvent(event, payload)
    : (events[event] || events[eventList[event]])();
  window.dispatchEvent(eventObj);
};

export const EVENTS = eventList;
