/* eslint-disable no-use-before-define */
import { isElement } from 'dottom@common/helpers/objects';
import TemplateNode from 'dottom@shared/template-interpreter';
import MissingDataException from 'dottom@common/exceptions/MissingDataException';
import { copyObjects, copyArray } from 'dottom@common/deep-copy';
import { addSvg } from 'dottom@common/svg';
import { createEvent } from 'dottom@shared/events';

let getSettings = () => {};
let createConfirmModal = () => {};
let showMessageDefaults = {};
let insertDefaults = () => {};

/**
 * Filters items based on whether certain fields match certain conditions
 * @param {*} filters
 * @param {*} item
 * @returns {boolean} True if the value is kept, false if the value needs to be filtered out.
 */
const filterItemSimple = (filters, item) => {
  let filterItem = false;
  Object.keys(filters).every((filterKey) => {
    const filter = filters[filterKey];

    if (typeof filter === 'function' && !filter(item[filterKey], item)) {
      filterItem = true;
      return false;
    }

    if (Array.isArray(filter) && !filter.includes(item[filterKey]) && filter !== item[filterKey]) {
      filterItem = true;
      return false;
    }

    if (filter !== item[filterKey]) {
      filterItem = true;
      return false;
    }

    return true;
  });

  return !filterItem;
};

const filterRelations = (itemKeys, itemId) => itemKeys.includes(itemId);

const filterCategories = (filterData, category) => {
  const {
    relations,
    idKey = 'id',
  } = filterData;

  if (category.children && category.children.length) {
    category.children = category.children.filter(filterCategories.bind(null, filterData));
  }

  if (!relations || !relations[category[idKey]] || !relations[category[idKey]].length) {
    if (!category.children || !category.children.length) {
      return false;
    }
  }
  return true;
};

const getFilteredData = (options) => {
  const {
    categories,
    relations,
    items,
    idKey = 'id',
    onFilterData = null,
  } = options;

  let {
    itemFilter = () => true,
  } = options;

  if (itemFilter !== null && typeof itemFilter === 'object') {
    itemFilter = filterItemSimple.bind(null, itemFilter);
  } else if (itemFilter !== 'function') {
    itemFilter = () => true;
  }

  const realItems = {};
  const realRelations = {};
  const listedItems = [];

  Object.keys(items).forEach((itemKey) => {
    const item = copyObjects({}, items[itemKey]);
    if (itemFilter(item)) {
      if (typeof item.id === 'undefined' || item.id === null) {
        item.id = +itemKey;
      }
      realItems[itemKey] = item;
    }
  });

  const bFilterRelations = filterRelations.bind(null, Object.keys(realItems).map(
    (itemKey) => +itemKey,
  ));

  Object.keys(relations).forEach((categoryId) => {
    const relation = relations[categoryId].filter(bFilterRelations);
    if (relation.length) {
      relation.forEach((itemId) => {
        if (!listedItems.includes(+itemId)) {
          listedItems.push(+itemId);
        }
      });
      realRelations[categoryId] = relation;
    }
  });

  realRelations[0] = [];

  Object.keys(realItems).forEach((itemId) => {
    if (!listedItems.includes(+itemId)) {
      realRelations[0].push(+itemId);
    }
  });

  const realCategories = categories.filter(filterCategories.bind(null, {
    relations: realRelations,
    idKey,
  }));

  const returnData = {
    categories: realCategories,
    relations: realRelations,
    items: realItems,
  };

  if (onFilterData && typeof onFilterData === 'function') {
    return onFilterData(returnData);
  }

  return returnData;
};

const initTemplateNode = (templateNode) => {
  templateNode.addBlockVisibility('separator', (_obj, _node, _value, _globals, data) => {
    const {
      categories = null,
      items = null,
    } = data;

    return (categories && categories.length && items && items.length);
  });

  templateNode.addVariableFunction('breadcrumbs', (_obj, node, value) => {
    if (value.length === 1) {
      return '';
    }
    const blocks = templateNode.getBlock('breadcrumb', true);
    const blockMap = {};
    blocks.forEach((block) => {
      if (!block.params.length) {
        blockMap.default = block;
      } else {
        blockMap[block.params[0]] = block;
      }
    });
    const contentArr = value.map((val, index) => {
      if (index < value.length - 1 || !blockMap.inactive) {
        return blockMap.default.content.parse({
          name: val,
          index,
        });
      }
      return blockMap.inactive.content.parse({
        name: val,
        index,
      });
    });
    const separator = (blockMap.separator && blockMap.separator.content.parse()) || node.params[0] || '';
    return contentArr.join(separator);
  });

  templateNode.addVariableFunction('categories', (_obj, _node, value) => {
    const block = templateNode.getBlock('category');
    const contentArr = value.map((val, index) => block.content.parse({
      ...val,
      index,
    }));
    return contentArr.join('');
  });

  templateNode.addVariableFunction('items', (_obj, _node, value) => {
    const block = templateNode.getBlock('item');
    const contentArr = value.map((val) => block.content.parse(val));
    return contentArr.join('');
  });
};

const setBrowserEvents = (templateNode, data, options = {}, privateData = {}, tempData = {}) => {
  const { el } = privateData;

  const {
    templates,
    onItemClick = null,
  } = options;

  const {
    categoryBrowser,
  } = templates;

  const active = categoryBrowser.activeClass;

  const bcEls = el.querySelectorAll('[data-bc-index');
  const catEls = el.querySelectorAll('[data-index]');
  const itemEls = el.querySelectorAll('[data-id]');

  const bcClick = (index, event) => {
    event.preventDefault();
    if (privateData.history[index]) {
      privateData.history.splice(index);
      data.selected = null;
      renderTemplateNode(templateNode, data, options, privateData);
    }
  };

  const catClick = (index, event) => {
    event.preventDefault();
    if (tempData.categories[index]) {
      const catData = tempData.categories[index];
      if (catData.id > -2) {
        privateData.history.push(tempData.categories[index]);
      } else {
        privateData.history.pop();
      }
      data.selected = null;
      renderTemplateNode(templateNode, data, options, privateData);
    }
  };

  const itemClick = (id, event) => {
    if (onItemClick && typeof onItemClick === 'function') {
      const itemClickStatus = onItemClick(id, event);
      if (itemClickStatus === false) {
        return;
      }
    }
    event.preventDefault();
    data.selected = +id;

    for (let idx = 0; idx < itemEls.length; idx += 1) {
      const itemEl = itemEls[idx];

      itemEl.classList.remove(active);
      if (+itemEl.dataset.id === +id) {
        itemEl.classList.add(active);
      }
    }

    el.dispatchEvent(createEvent('itemSelected', {
      el,
      data,
      selected: +id,
    }));
  };

  for (let bcIdx = 0; bcIdx < bcEls.length; bcIdx += 1) {
    const bcEl = bcEls[bcIdx];

    bcEl.addEventListener('click', bcClick.bind(null, bcEl.dataset.bcIndex));
  }

  for (let catIdx = 0; catIdx < catEls.length; catIdx += 1) {
    const catEl = catEls[catIdx];

    catEl.addEventListener('click', catClick.bind(null, catEl.dataset.index));
  }

  for (let itemIdx = 0; itemIdx < itemEls.length; itemIdx += 1) {
    const itemEl = itemEls[itemIdx];

    itemEl.addEventListener('click', itemClick.bind(null, itemEl.dataset.id));
  }
};

const rerouteEvents = ($modal, el, setOkStatus) => {
  el.addEventListener('browserRendered', (event) => {
    $modal.trigger('browserRendered', event.detail);
    setOkStatus();
  });
  el.addEventListener('itemSelected', (event) => {
    $modal.trigger('itemSelected', event.detail);
    setOkStatus();
  });
};

const renderTemplateNode = (templateNode, data, options = {}, privateData = {}) => {
  const { el } = privateData;
  const { text, onPrepareData = null, templates = {} } = options;
  const { categoryBrowser = {} } = templates;
  const { backIcon = '', allIcon = '' } = categoryBrowser;
  privateData.history = privateData.history || [];

  const breadcrumbs = ['..'];

  privateData.history.forEach((historyItem) => {
    breadcrumbs.push(historyItem.name);
  });

  const category = privateData.history.length ? privateData.history.slice(-1)[0] : null;
  const categoryId = category ? category.id : 0;
  const itemIds = Object.keys(options.items).map((itemId) => +itemId);
  itemIds.sort();
  const items = categoryId > -1
    ? (options.relations[categoryId] || []).map((itemId) => options.items[itemId])
    : itemIds.map((itemId) => options.items[itemId]);
  const categories = copyArray(category
    ? category.children || []
    : options.categories);

  if (categoryId === 0 && itemIds.length !== (options.relations[categoryId] || []).length) {
    categories.push(
      {
        name: `${addSvg(allIcon, '', '', 'icon')} ${text['categoryBrowser.all']}`,
        id: -1,
      },
    );
  } else if (categoryId !== 0) {
    categories.unshift(
      {
        name: `${addSvg(backIcon, '', '', 'icon')} ${text['categoryBrowser.back']}`,
        id: -2,
      },
    );
  }

  const parseData = {
    breadcrumbs,
    categories,
    items,
  };

  if (onPrepareData && typeof onPrepareData === 'function') {
    onPrepareData(parseData, options);
  }

  el.innerHTML = templateNode.parse(parseData);

  setBrowserEvents(templateNode, data, options, privateData, {
    categories,
    items,
  });

  el.dispatchEvent(createEvent('browserRendered', {
    el,
    data,
  }));
};

const createTemplateString = (categoryBrowser) => `
${categoryBrowser.body}

{{block:breadcrumb}}
${categoryBrowser.breadcrumb}
{{/block}}

{{block:breadcrumb:inactive}}
${categoryBrowser.breadcrumbInactive}
{{/block}}

{{block:breadcrumb:separator}}
${categoryBrowser.breadcrumbSeparator}
{{/block}}

{{block:category}}
${categoryBrowser.category}
{{/block}}

{{block:item}}
${categoryBrowser.item}
{{/block}}
`;

const createCategoryBrowserElement = (options = {}) => {
  const el = document.createElement('div');
  const data = {};

  const {
    templates,
    onPrepareTemplate = null,
    onPrepareNode = null,
  } = options;

  const {
    categoryBrowser,
  } = templates;

  let templateString = createTemplateString(categoryBrowser);

  if (onPrepareTemplate && typeof onPrepareTemplate === 'function') {
    templateString = onPrepareTemplate(templateString, categoryBrowser, options);
  }

  const templateNode = new TemplateNode(templateString);

  initTemplateNode(templateNode);

  if (onPrepareNode && typeof onPrepareNode === 'function') {
    onPrepareNode(templateNode);
  }

  renderTemplateNode(templateNode, data, options, {
    el,
  });

  return {
    el,
    data,
  };
};

/**
 * Validates the properties of an object.
 * @param {Object} options The options object that needs to be validated
 */
const validateData = (options = {}) => {
  const missingData = [];
  if (!options.categories) {
    missingData.push('categories');
  }
  if (!options.relations) {
    missingData.push('relations');
  }
  if (!options.items) {
    missingData.push('items');
  }

  if (missingData.length) {
    throw new MissingDataException(missingData.join(', '));
  }
};

const createCategoryBrowser = (options = {}) => {
  const opt = getSettings(options);

  validateData(opt);

  const {
    categories,
    relations,
    items,
  } = getFilteredData(opt);

  const {
    inlineElement = null,
  } = opt;

  let {
    onConfirm = null,
    okAttributes = '',
  } = opt;

  const {
    el,
    data,
  } = createCategoryBrowserElement({
    ...opt,
    categories,
    relations,
    items,
  });

  let didInline = false;

  if (inlineElement) {
    if (isElement(inlineElement)) {
      inlineElement.appendChild(el);
      didInline = true;
    }
  }

  if (!didInline) {
    if (onConfirm) {
      onConfirm = onConfirm.bind(null, data);
    } else {
      onConfirm = ($modal) => {
        const item = items[data.selected];
        $modal.modal('hide');
        $modal.trigger('confirmed', [data.selected, {
          preview: item.thumb,
          name: item.name,
        }]);
      };
    }
    okAttributes = [okAttributes, 'data-confirm="true"'].join(' ');

    const $modal = createConfirmModal({
      ...opt,
      content: el,
      onConfirm,
      okAttributes,
    });

    $modal.data('browserElement', el);
    $modal.data('browserData', data);

    const $okBtn = $modal.find('[data-confirm]');

    const setOkStatus = () => {
      $okBtn.prop('disabled', !data.selected);
    };

    rerouteEvents($modal, el, setOkStatus);
    setOkStatus();

    return $modal;
  }
  return el;
};

const DEFAULT = {
  templates: {
    categoryBrowser: {
      body: `
  <div>
    {{breadcrumbs}}
    <div class="row-flex row-flex-centered">
      {{categories}}
    </div>
    {{block:separator}}
    <hr />
    {{/block}}
    <div class="row-flex row-flex-centered">
      {{items}}
    </div>
  </div>`,
      breadcrumb: '<a href="#" data-bc-index="{{index}}" class="block-pd-minimal">{{name}}</a>',
      breadcrumbInactive: '<span class="block-pd-minimal">{{name}}</span>',
      breadcrumbSeparator: '<span class="block-pd-minimal">/</span>',
      category: `
  <div class="col-flex col-flex-12 col-flex-sm-3">
      <a href="#" class="thumbnail" data-index="{{index}}">{{name}}</a>
  </div>
  `,
      item: `
  <div class="col-flex col-flex-12 col-flex-sm-4">
      <a href="#" class="thumbnail" data-id="{{id}}">{{name}}</a>
  </div>
  `,
      activeClass: 'active',
      backIcon: 'chevron-left',
      allIcon: 'list',
    },
  },
  text: {
    'categoryBrowser.all': 'All',
  },
};

const initDefaults = () => {
  insertDefaults(DEFAULT, showMessageDefaults);
};

const setupCategoryBrowser = (options = {}) => {
  if (options.getSettings) {
    ({ getSettings } = options);
  }
  if (options.createConfirmModal) {
    ({ createConfirmModal } = options);
  }

  if (options.insertDefaults) {
    ({ insertDefaults } = options);
  }

  if (options.showMessageDefaults) {
    ({ showMessageDefaults } = options);
  }
  initDefaults();
};

export {
  createCategoryBrowser,
  setupCategoryBrowser,
};
