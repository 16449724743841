import $ from 'jquery';

const backdrop = '.dropdown-menu-backdrop';
const toggle = '[data-toggle="dropdown-menu"]';
const hoverOpen = '.navigation-hover';
const smoothOpen = '.navigation-smooth';
const submenu = '.submenu';

const getParent = ($this) => {
  let selector = $this.attr('data-target');

  if (!selector) {
    selector = $this.attr('href');
    selector = selector && /#[A-Za-z]/.test(selector) && selector.replace(/.*(?=#[^\s]*$)/, ''); // strip for ie7
  }

  const $parent = selector && $(selector);

  return $parent && $parent.length ? $parent : $this.parent();
};

function DropdownMenu(element) {
  $(element).on('click.bs.dropdown-menu', this.toggle);
  const $parent = getParent($(element));
  if ($parent && $parent.length) {
    $parent.on('mouseenter.bs.dropdown-menu', this.hover);
    $parent.on('mouseleave.bs.dropdown-menu', this.blur);
  }
}

DropdownMenu.VERSION = '3.3.7';

const clearMenu = ($this, className, e) => {
  const $parent = getParent($this);
  const relatedTarget = { relatedTarget: $this[0], addedClass: className };
  let event = e;

  if (!$parent.hasClass(className)) {
    return;
  }

  if (event && event.type === 'click' && /input|textarea/i.test(e.target.tagName) && $.contains($parent[0], e.target)) {
    return;
  }

  if ($parent.is('.open.hover')) {
    $parent.removeClass(className);
    return;
  }

  event = $.Event('hide.bs.dropdown-menu', relatedTarget);
  $parent.trigger(event);

  if (event.isDefaultPrevented()) {
    return;
  }

  $this.attr('aria-expanded', 'false');
  $parent.removeClass(className).trigger($.Event('hidden.bs.dropdown-menu', relatedTarget));
};

const clearMenus = ($ignore, e) => {
  let $ignored = $ignore;
  if (e && e.which === 3) {
    return;
  }
  if (!$ignored) {
    $ignored = $();
  }
  $(backdrop).remove();

  $(toggle).each(function onToggleClick() {
    const $this = $(this);
    const $parent = getParent($this);
    if ($ignored.is($parent)) {
      return;
    }
    clearMenu($this, 'open', e);
  });
};

function triggerOpenEvents($this, className, e) {
  let event = e;
  const $parent = getParent($this);
  if ($parent.is('.open, .hover')) {
    if (className) {
      $parent.addClass(className);
    }
    return;
  }
  const relatedTarget = { relatedTarget: $this[0], addedClass: className };
  event = $.Event('show.bs.dropdown-menu', relatedTarget);
  $parent.trigger(event);

  if (event.isDefaultPrevented()) {
    return;
  }

  $this
    .trigger('focus')
    .attr('aria-expanded', 'true');

  if (className) {
    $parent.addClass(className);
  }
  $parent
    .trigger($.Event('shown.bs.dropdown-menu', relatedTarget));
}

DropdownMenu.prototype.toggle = function toggleDropdown(e) {
  const $this = $(this);
  if ($this.is('.disabled, :disabled')) {
    return false;
  }

  const $parent = getParent($this);
  const isActive = $parent.hasClass('open');

  let $current = $this;
  let $ignore = $();

  while ($current.parent().length > 0) {
    $current = $current.parent();
    if ($current.children(toggle)) {
      $ignore = $ignore.add(getParent($current.children(toggle)));
    }
  }

  if (isActive) {
    $ignore = $ignore.not($parent);
  }

  clearMenus($ignore);

  if (!isActive) {
    if ('ontouchstart' in document.documentElement && !$parent.closest('.navbar-nav').length) {
      // if mobile we use a backdrop because click events don't delegate
      $(document.createElement('div'))
        .addClass(backdrop)
        .insertAfter($(this))
        .on('click', clearMenus.bind(null, null));
    }

    triggerOpenEvents($(this), 'open', e);
  }

  return false;
};

DropdownMenu.prototype.hover = function hoverDropdown(e) {
  const $parent = $(this);
  const $this = $parent.children(toggle);

  triggerOpenEvents($this, 'hover', e);
};

DropdownMenu.prototype.blur = function blurDropdown(e) {
  const $parent = $(this);
  const $this = $parent.children(toggle);
  clearMenu($this, 'hover', e);

  if ($parent.is('.open')) {
    return;
  }
  $parent.find(toggle).not($this).each(function blurClearDropdown() {
    clearMenu($(this), 'open', e);
  });
};

DropdownMenu.prototype.keydown = function keydownDropdown(e) {
  if (!/(38|40|27|32)/.test(e.which) || /input|textarea/i.test(e.target.tagName)) {
    return true;
  }

  const $this = $(this);

  e.preventDefault();
  e.stopPropagation();

  if ($this.is('.disabled, :disabled')) {
    return true;
  }

  const $parent = getParent($this);
  const isActive = $parent.hasClass('open');

  if ((!isActive && e.which !== 27) || (isActive && e.which === 27)) {
    if (e.which === 27) {
      $parent.find(toggle).trigger('focus');
    }
    return $this.trigger('click');
  }

  const desc = ' li:not(.disabled):visible a';
  const $items = $parent.find(`.dropdown-menu${desc}`);

  if (!$items.length) {
    return true;
  }

  let index = $items.index(e.target);

  if (e.which === 38 && index > 0) {
    index -= 1;
  } // up
  if (e.which === 40 && index < $items.length - 1) {
    index += 1;
  } // down
  if (!~index) {
    index = 0;
  }

  $items.eq(index).trigger('focus');
  return true;
};

function Plugin(option) {
  return this.each(function forEachDropdownElement() {
    const $this = $(this);
    let data = $this.data('bs.dropdown-menu');

    if (!data) $this.data('bs.dropdown-menu', (data = new DropdownMenu(this)));
    if (typeof option === 'string') data[option].call($this);
  });
}

$.fn.dropdownMenu = Plugin;
$.fn.dropdownMenu.Constructor = DropdownMenu;

const menuDropdown = `${hoverOpen} .menu-dropdown`;
const smoothDropdown = `${smoothOpen} .menu-dropdown`;

$(document)
  .on('click.bs.dropdown-menu.data-api', clearMenus.bind(null, null))
  .on('click.bs.dropdown-menu.data-api', '.menu-dropdown form', (e) => {
    e.stopPropagation();
  })
  .on('click.bs.dropdown-menu.data-api', toggle, DropdownMenu.prototype.toggle)
  .on('keydown.bs.dropdown-menu.data-api', toggle, DropdownMenu.prototype.keydown)
  // .on('keydown.bs.dropdown-menu.data-api', '.dropdown-menu', DropdownMenu.prototype.keydown)
  .on('mouseenter.bs.dropdown-menu.data-api', menuDropdown, DropdownMenu.prototype.hover)
  .on('mouseleave.bs.dropdown-menu.data-api', menuDropdown, DropdownMenu.prototype.blur)
  .on('show.bs.dropdown-menu', smoothDropdown, function onDropdownShow(e) {
    e.preventDefault();
    const $parent = $(this);
    const $this = $parent.children(toggle);
    const $submenu = $parent.find(submenu).first();
    $submenu.stop(true, true).delay(250).slideDown(400, () => {
      $this.attr('aria-expanded', 'true');
      $parent.addClass(e.addedClass);
      $submenu.removeAttr('style');
    });
    return false;
  })
  .on('hide.bs.dropdown-menu', smoothDropdown, function onDropdownHide(e) {
    e.preventDefault();
    const $parent = $(this);
    const $this = $parent.children(toggle);
    const $submenu = $parent.find(submenu).first();
    $submenu.stop(true, true).delay(250).slideUp(400, () => {
      $this.attr('aria-expanded', 'false');
      $parent.removeClass(e.addedClass);
      $submenu.removeAttr('style');
    });
    return false;
  });
