export const throttle = (callback, delay, wait = false) => {
  let inThrottle = false;
  return (...args) => {
    if (!inThrottle) {
      callback(...args);
      inThrottle = true;
      setTimeout(() => {
        if (wait) {
          Promise.resolve(callback(...args)).finally(() => {
            inThrottle = false;
          });
        } else {
          callback(...args);
          inThrottle = false;
        }
      }, delay);
    }
  };
};

export const debounce = (callback, delay, wait = false) => {
  let timeout;
  let inWaiting = 0;
  return (...args) => {
    const execute = () => {
      timeout = null;
      if (wait) {
        inWaiting = 1;
        Promise.resolve(callback(...args)).finally(() => {
          if (inWaiting === 2) {
            clearTimeout(timeout);
            timeout = setTimeout(execute, delay);
          }
          inWaiting = 0;
        });
      } else {
        callback(...args);
      }
    };
    if (!wait || !inWaiting) {
      clearTimeout(timeout);
      timeout = setTimeout(execute, delay);
    } else {
      inWaiting = 2;
    }
  };
};
