import dynamicLoad from 'dottom@common/dynamic-loading';
import cssLoad from 'dottom@common/css-load';

const specialModes = {
  json: {
    name: 'javascript',
    json: true,
  },
};

const specialFiles = {
  json: 'javascript',
};

const loadSpecialFiles = (loader, type) => {
  const realType = specialFiles[type] || type;
  if (Array.isArray(realType)) {
    realType.forEach((subType) => {
      loader.push(dynamicLoad(`/assets/js/codemirror/${subType}.js`));
    });
  } else {
    loader.push(dynamicLoad(`/assets/js/codemirror/${realType}.js`));
  }
};

export default (type, label = '') => {
  const container = document.createElement('div');
  if (label) {
    const labelElem = document.createElement('label');
    labelElem.textContent = label;
    container.appendChild(labelElem);
  }
  const textarea = document.createElement('textarea');
  container.appendChild(textarea);
  const loader = [];
  loader.push(cssLoad('/assets/css/codemirror/codemirror.css'));
  loader.push(dynamicLoad('/assets/js/codemirror/codemirror.js'));
  Promise.all(loader).then(() => {
    loader.splice(0);
    if (type === 'htmlmixed') {
      loader.push(dynamicLoad('/assets/js/codemirror/xml.js'));
      loader.push(dynamicLoad('/assets/js/codemirror/javascript.js'));
      loader.push(dynamicLoad('/assets/js/codemirror/css.js'));
    }
    loadSpecialFiles(loader, type);
    Promise.all(loader).then(() => {
      window.CodeMirror.fromTextArea(textarea, {
        lineNumbers: true,
        mode: specialModes[type] || type,
      });
    });
  });
  return container;
};
