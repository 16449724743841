export const isObject = (obj) => obj !== null && typeof obj === 'object';

export const isNode = (obj) => (
  Node !== null && typeof Node !== 'undefined' && ['object', 'function'].includes(typeof Node)
    ? obj instanceof Node
    : obj && obj !== null && typeof obj === 'object' && typeof obj.nodeType === 'number' && typeof obj.nodeName === 'string'
);

export const isElement = (obj) => (
  HTMLElement !== null && typeof HTMLElement !== 'undefined' && ['object', 'function'].includes(typeof HTMLElement)
    ? obj instanceof HTMLElement
    : obj && obj !== null && typeof obj === 'object' && obj.nodeType === 1 && typeof obj.nodeName === 'string'
);
