import $ from 'jquery';
import createSyntaxHighlight from './modals/syntax-highlight';
import createEditor from './modals/text-editor';
import { createCategoryBrowser, setupCategoryBrowser } from './modals/category-browser';

window.dottom = window.dottom || {};
const { dottom } = window;

const getDefaultModalTemplate = () => `
<div id="{{id}}" class="{{modal_container_classes}}" role="dialog">
    <div class="{{modal_classes}}">
        <div class="{{content_classes}}" {{content_attributes}}>
            {{header_block}}
            {{content_block}}
            {{footer_block}}
        </div>
    </div>
    {{alert}}
</div>
`;

const MODALS_DEFAULTS = {
  id: 'generic-modal',
  title: '',
  content: '',
  closeable: true,
  draggable: true,
  autoOpen: true,
  buttons: [],
  text: {
    confirmTitle: 'Confirm',
    confirm: 'Are you sure?',
    ok: 'OK',
    cancel: 'Cancel',
  },
  templates: {
    modal: getDefaultModalTemplate(),
    modalContainerClasses: 'modal fade modal-dynamic modal-dynamic-centered',
    modalClasses: 'modal-dialog modal-lg modal-contain',
    button: '<button type="{{type}}" class="btn {{classes}}" {{attributes}}>{{label}}</button>',
    buttonLink: '<a href="{{url}}" class="btn {{classes}}" {{attributes}}>{{label}}</a>',
    buttonClasses: 'btn-default',
    alerts: '<div id="alert-{{alert}}" class="alertbox-container alertbox-container-bottom alertbox-container-left"></div>',
    blocks: {
      header: `<div class="{{header_classes}}" {{header_attributes}}>
    <div class="modal-header-btns">
        {{resize_btns}}
        {{close_btn}}
    </div>
    <h4 class="modal-title">{{title}}</h4>
</div>`,
      content: '<div class="modal-body">{{content}}</div>',
      footer: '<div class="modal-footer">{{footer}}</div>',
    },
    contentClasses: 'modal-content',
    contentDraggableClasses: 'block-position-container',
    headerClasses: 'modal-header',
    headerDraggableClasses: 'block-cursor-pointer',
    closeButton: '<button type="button" class="modal-header-btn" data-dismiss="modal">&times;</button>',
    resizeButtons: `<button type="button" class="modal-header-btn modal-resize" data-toggle="modal-size">
    <span class="modal-resize-inactive">&varr;</span>
    <span class="modal-resize-active">&duarr;</span>
</button>`,
  },
};

const getSettings = (options = {}, ...additional) => $.extend(
  true,
  {},
  { ...MODALS_DEFAULTS },
  (($.showMessage && $.showMessage.defaults) || {}),
  ((window.dottom.showMessage && window.dottom.showMessage.defaults) || {}),
  ...additional,
  options,
);

const setText = (template, options = {}) => {
  const opt = getSettings(options);
  return template.replace(/{{\s*text:(.+?)\s*}}/g, (...match) => (opt && opt.text && opt.text[match[1]]) || '');
};

const getContentElement = (contentItem, wrapString = false) => {
  if (contentItem instanceof Element || contentItem instanceof $) {
    return contentItem;
  }
  if (typeof contentItem === 'string') {
    return wrapString ? `<div>${contentItem}</div>` : contentItem;
  }
  if (contentItem.type) {
    switch (contentItem.type) {
      case 'highlight':
        return createSyntaxHighlight(contentItem.mode, contentItem.label || '');
      case 'editor': {
        const props = {
          csrf: dottom.regenerateCSRF || (() => {}),
          allowedAttr: contentItem.allowedAttr || null,
          lang: contentItem.lang || 'en',
          urls: contentItem.urls || {},
          element: contentItem.element || null,
        };
        return createEditor(props, contentItem.label || '');
      }
      default:
        break;
    }
  }
  return null;
};

const setContainerLimits = ($container, position) => {
  $container.css({
    left: 0,
    top: 0,
    position: '',
    width: '',
    height: '',
  });
  const boundingRect = $container[0].getBoundingClientRect();
  const newPosition = {};
  const limits = {
    left: -boundingRect.left,
    right: boundingRect.right - boundingRect.width,
    top: -boundingRect.top,
    bottom: boundingRect.bottom - boundingRect.height,
  };
  newPosition.left = Math.min(limits.right, Math.max(limits.left, position.left));
  newPosition.top = Math.min(limits.bottom, Math.max(limits.top, position.top));
  $container.css({
    position: 'absolute',
    left: newPosition.left,
    width: boundingRect.width,
    height: boundingRect.height,
  });
  const tempRect = $container[0].getBoundingClientRect();
  $container.css({
    top: newPosition.top - (tempRect.top - boundingRect.top),
  });
  return newPosition;
};

const getMouseOffset = (mouseOld, mouseNew) => ({
  left: mouseNew.left - mouseOld.left,
  top: mouseNew.top - mouseOld.top,
});

const setElementDraggable = ($modal) => {
  const $dragContainer = $modal.find('[data-modal-drag-container]');
  const position = { left: 0, top: 0 };
  const mouse = { left: 0, top: 0 };
  let dragInitialized = false;
  if ($dragContainer.length && $modal.find('[data-modal-drag]').length) {
    const initDraggable = ($dragHandler = null) => {
      const $element = $($dragHandler ?? $modal.find('[data-modal-drag]'));
      if (!$element) {
        return;
      }
      $element.css('user-select', 'none');
      let isDrag = false;
      let hasDragged = false;

      $element.on('mousedown', (event) => {
        isDrag = true;
        mouse.left = event.pageX;
        mouse.top = event.pageY;
      });

      if (dragInitialized) {
        return;
      }

      dragInitialized = true;

      setContainerLimits($dragContainer, position);
      $dragContainer.on('mouseup', (event) => {
        isDrag = false;
        if (hasDragged) {
          const oldPosition = setContainerLimits($dragContainer, position);
          const mouseOffset = getMouseOffset(mouse, {
            left: event.pageX,
            top: event.pageY,
          });
          const newPosition = setContainerLimits($dragContainer, {
            left: oldPosition.left + mouseOffset.left,
            top: oldPosition.top + mouseOffset.top,
          });
          position.left = newPosition.left;
          position.top = newPosition.top;
        }
        hasDragged = false;
      });

      const onMouseMove = (event) => {
        if (isDrag) {
          hasDragged = true;
          const oldPosition = setContainerLimits($dragContainer, position);
          const mouseOffset = getMouseOffset(mouse, {
            left: event.pageX,
            top: event.pageY,
          });
          setContainerLimits($dragContainer, {
            left: oldPosition.left + mouseOffset.left,
            top: oldPosition.top + mouseOffset.top,
          });
        }
      };

      const onMouseUp = () => {
        isDrag = false;
        hasDragged = false;
        setContainerLimits($dragContainer, position);
      };

      const onResize = () => {
        setContainerLimits($dragContainer, position);
      };

      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
      window.addEventListener('resize', onResize);

      $modal.on('hidden.bs.modal', () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
        window.removeEventListener('resize', onResize);
      });
      $modal.on('updateContent', onResize);
    };

    $modal.on('shown.bs.modal', () => {
      initDraggable();
    });
    $modal.on('add.draggable.modal', (_e, element) => {
      initDraggable(element);
    });
  }
};

const createModal = (options = {}) => {
  const opt = getSettings(options);
  const {
    id,
    title,
    content,
    buttons,
    closeable,
    draggable,
    alert = '',
    onBeforeRender = null,
  } = opt;

  const containerClassName = (opt.containerClassName || opt.containerClassName === '' ? opt.containerClassName : opt.templates.modalContainerClasses);
  const modalClassName = (opt.modalClassName || opt.modalClassName === '' ? opt.modalClassName : opt.templates.modalClasses);
  const headerClassName = (opt.headerClassName || opt.headerClassName === '' ? opt.headerClassName : opt.templates.headerClasses);
  const contentClassName = (opt.contentClassName || opt.contentClassName === '' ? opt.contentClassName : opt.templates.contentClasses);
  let fullHeaderClassName = headerClassName;
  let fullContentClassName = contentClassName;
  if (draggable) {
    const headerDraggableClassName = (opt.headerDraggableClassName || opt.headerDraggableClassName === '' ? opt.headerDraggableClassName : opt.templates.headerDraggableClasses);
    const contentDraggableClassName = (opt.contentDraggableClassName || opt.contentDraggableClassName === '' ? opt.contentDraggableClassName : opt.templates.contentDraggableClasses);
    if (headerDraggableClassName) {
      fullHeaderClassName = `${fullHeaderClassName} ${headerDraggableClassName}`;
    }
    if (contentDraggableClassName) {
      fullContentClassName = `${fullContentClassName} ${contentDraggableClassName}`;
    }
  }
  let modal = `${opt.templates.modal}`;
  modal = modal.replace(/{{\s*id\s*}}/, id);
  if (!opt.hideHeader) {
    modal = modal.replace(/{{\s*header_block\s*}}/, opt.templates.blocks.header);
    modal = modal.replace(/{{\s*header_attributes\s*}}/, draggable ? ' data-modal-drag' : '');
    modal = modal.replace(/{{\s*close_btn\s*}}/, closeable && !opt.hideCloseBtn ? opt.templates.closeButton : '');
    modal = modal.replace(/{{\s*resize_btns\s*}}/, !opt.hideResizeBtn ? opt.templates.resizeButtons : '');
    modal = modal.replace(/{{\s*title\s*}}/, title);
  } else {
    modal = modal.replace(/{{\s*header_block\s*}}/, '');
  }
  modal = modal.replace(/{{\s*modal_container_classes\s*}}/, containerClassName);
  modal = modal.replace(/{{\s*modal_classes\s*}}/, modalClassName);
  modal = modal.replace(/{{\s*header_classes\s*}}/, fullHeaderClassName);
  modal = modal.replace(/{{\s*content_classes\s*}}/, fullContentClassName);
  modal = modal.replace(/{{\s*content_attributes\s*}}/, draggable ? ' data-modal-drag-container' : '');
  if (!opt.hideContent) {
    modal = modal.replace(/{{\s*content_block\s*}}/, opt.templates.blocks.content);
    if (typeof content !== 'string') {
      modal = modal.replace(/{{\s*content\s*}}/, '<slot />');
    } else {
      modal = modal.replace(/{{\s*content\s*}}/, content);
    }
  } else {
    modal = modal.replace(/{{\s*content_block\s*}}/, '');
  }

  if (alert) {
    let alertTemplate = opt.templates.alerts;
    alertTemplate = alertTemplate.replace(/{{\s*alert\s*}}/, alert);
    modal = modal.replace(/{{\s*alert\s*}}/, alertTemplate);
  } else {
    modal = modal.replace(/{{\s*alert\s*}}/, '');
  }

  modal = modal.replace(/{{\s*footer_block\s*}}/, opt.hideFooter ? '' : opt.templates.blocks.footer);

  const $modal = $(modal);

  const $buttons = $('<div></div>');

  if (!opt.hideFooter) {
    buttons.forEach((button) => {
      if (typeof button === 'string' || button instanceof $) {
        $buttons.append(button);
      } else if (button instanceof Element) {
        $buttons[0].appendChild(button);
      } else {
        let html = button.html || (button.url ? opt.templates.buttonLink : opt.templates.button);
        html = html.replace(/\{\{\s*type\s*\}\}/, button.type || 'button');
        html = html.replace(/\{\{\s*classes\s*\}\}/, button.className || opt.templates.buttonClasses);
        html = html.replace(/\{\{\s*attributes\s*\}\}/, button.attributes || '');
        html = html.replace(/\{\{\s*label\s*\}\}/, button.label || '');
        html = html.replace(/\{\{\s*url\s*\}\}/, button.url || '');
        const $button = $(html);
        if (button.onClick) {
          $button.on('click', button.onClick.bind(null, $modal));
        }
        $buttons.append($button);
      }
    });

    const footer = $modal.find('*').contents().filter(function filterFooterElements() {
      return this.nodeType === Node.TEXT_NODE && /\{\{\s*footer\s*\}\}/.test(this.nodeValue);
    });
    footer.each(function processFooters() {
      const textNodes = this.nodeValue.split(/\{\{\s*footer\s*\}\}/);
      const $parent = $(this.parentNode);
      $parent.empty();
      $parent.append(document.createTextNode(textNodes.shift()));
      textNodes.forEach((text) => {
        $parent.append($buttons.children().clone(true));
        $parent.append(document.createTextNode(text));
      });
    });
  }

  if (!opt.hideContent && typeof content !== 'string') {
    const $slot = $modal.find('slot');
    if (Array.isArray(content)) {
      content.forEach((contentItem) => {
        const contentInsert = getContentElement(contentItem, true);
        if (contentInsert) {
          $(contentInsert).insertBefore($slot);
        }
      });
      $slot.remove();
    } else {
      $slot.replaceWith(getContentElement(content));
    }
  }

  const $toggleBtn = $modal.find('[data-toggle="modal-size"]');

  if ($toggleBtn.length) {
    const $container = $modal.find(`.${$toggleBtn.data('container') || 'modal-dialog'}`);
    if ($container.length) {
      $toggleBtn.on('click', function modalSizeEvent() {
        const $this = $(this);
        const classToggle = $this.data('class') || 'modal-contain-vh';
        if (!$this.is('.in')) {
          $container.addClass(classToggle);
          $this.addClass('in');
        } else {
          $container.removeClass(classToggle);
          $this.removeClass('in');
        }
      });

      const onResize = () => {
        if (!$modal || !$modal.length) {
          return;
        }
        const modalDialog = $modal.find('.modal-dialog')[0];
        const modalSize = Math.max(modalDialog.scrollHeight, modalDialog.offsetHeight);
        const dummySizer = document.createElement('div');
        dummySizer.style.position = 'fixed';
        dummySizer.style.height = '100vh';
        document.body.appendChild(dummySizer);
        const dummySize = dummySizer.scrollHeight;
        document.body.removeChild(dummySizer);
        dummySizer.style.height = '100%';
        document.body.appendChild(dummySizer);
        const screenSize = dummySizer.scrollHeight;
        document.body.removeChild(dummySizer);

        if ((screenSize === modalSize && dummySize > modalSize) || $toggleBtn.is('.in')) {
          $toggleBtn.addClass('active');
        } else {
          $toggleBtn.removeClass('active');
        }
      };

      window.addEventListener('resize', onResize);

      $modal.on('hidden.bs.modal', () => {
        window.removeEventListener('resize', onResize);
      });

      $modal.on('resize.bs.modal', onResize);

      $modal.on('show.bs.modal', onResize);
      $modal.on('shown.bs.modal', onResize);
    }
  }

  if (draggable) {
    setElementDraggable($modal);
  }

  $modal.on('hidden.bs.modal', function modalHiddenEvent() {
    $(this).remove();
  });

  $('body').append($modal);

  if (!closeable) {
    $modal.modal({ backdrop: 'static', keyboard: false });
  }

  if (onBeforeRender && typeof onBeforeRender === 'function') {
    onBeforeRender($modal);
  }

  if (opt.autoOpen) {
    $modal.modal('show');
  }

  return $modal;
};

const insertDefaults = (insert, source) => {
  Object.keys(insert).forEach((key) => {
    if (!source[key]) {
      source[key] = insert[key];
    } else if (typeof insert[key] === 'object') {
      insertDefaults(insert[key], source[key]);
    }
  });
};

const createConfirmModal = (options = {}) => {
  const opt = getSettings(options);
  const okBtn = {
    label: opt.okLabel || opt.text.ok,
    className: opt.okClassName || opt.templates.buttonClasses,
    attributes: opt.okAttributes || '',
  };
  if (opt.onConfirm) {
    okBtn.onClick = opt.onConfirm;
  }
  const cancelBtn = {
    label: opt.cancelLabel || opt.text.cancel,
    className: opt.cancelClassName || opt.templates.buttonClasses,
    attributes: 'data-dismiss="modal"',
  };
  const buttons = [...(options.footer || []), okBtn, cancelBtn];
  return createModal({
    title: opt.text.confirmTitle, content: opt.text.confirm, ...options, buttons,
  });
};

const showMessageDefaults = $.extend(
  true,
  { ...MODALS_DEFAULTS },
  (($.showMessage && $.showMessage.defaults) || {}),
  ((window.dottom.showMessage && window.dottom.showMessage.defaults) || {}),
);

createModal.confirm = createConfirmModal;
createModal.defaults = showMessageDefaults;
createModal.getSettings = getSettings;
createModal.setText = setText;
createModal.categoryBrowser = createCategoryBrowser;

setupCategoryBrowser({
  getSettings,
  createConfirmModal,
  insertDefaults,
  showMessageDefaults,
});

export default createModal;
export {
  createConfirmModal as getConfirm,
  getSettings,
  setText,
  insertDefaults,
};
