import { v1 as uuid1 } from 'uuid';
import InvalidDOMElementException from 'dottom@common/exceptions/InvalidDOMElementException';

/**
 * Creates a FormData object containing the chunk information for a file.
 * @param {File} file - File object.
 * @param {number} chunkSize - The chunk size.
 * @param {number} chunkIndex - The chunk index.
 * @param {string} uuid - The UUID.
 * @param {string} fieldName - The name of the $_FILE field.
 */
export const prepareFileChunk = (file, chunkSize, chunkIndex = 0, uuid = null, fieldName = 'userfile') => {
  const fileSize = file.size;
  const totalChunks = Math.ceil(fileSize / chunkSize);
  const chunkOffset = chunkSize * chunkIndex;
  const chunkEnd = chunkOffset + chunkSize;
  const chunk = file.slice(chunkOffset, chunkEnd, file.type);
  const formData = new FormData();
  formData.append(fieldName, chunk, file.name);
  formData.append('chunk_index', chunkIndex);
  formData.append('total_chunk_count', totalChunks);
  formData.append('total_file_size', fileSize);
  formData.append('uuid', uuid || uuid1());
  return formData;
};

/**
 * Creates a file chunk queue for later use.
 * @param {*} fileInputElement - An input element with type file.
 * @param {*} chunkSize - The maximum chunk size.
 * @param {*} queue - A file chunk queue.
 * @returns {array} A file chunk queue.
 */
export const getFileChunkQueue = (fileInputElement, chunkSize, queue = []) => {
  // Throws an error if the element isn't an input element
  if (!(fileInputElement instanceof HTMLInputElement)) {
    throw new InvalidDOMElementException('The object is not an input element');
  }
  // Throws an error if the input element isn't a file uploading input
  if (fileInputElement.type !== 'file') {
    throw new InvalidDOMElementException('The input element isn\'t of type "file"');
  }
  const fieldName = fileInputElement.name.replace(/\[\]$/, '');
  for (let idx = 0; idx < fileInputElement.files.length; idx += 1) {
    const file = fileInputElement.files[idx];
    const fileSize = file.size;
    const totalChunks = Math.ceil(fileSize / chunkSize);
    const uuid = uuid1();
    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex += 1) {
      queue.push(prepareFileChunk(file, chunkSize, chunkIndex, uuid, fieldName));
    }
  }
  return queue;
};

/**
 * Serializes form data.
 * @param {*} obj - An object containing form data.
 * @param {*} prefix - A prefix.
 * @param {*} toObject - Whether it should serialize to object or not.
 */
export const serialize = (obj, prefix, toObject = false) => {
  const returnValue = toObject && toObject !== true ? toObject : [];

  Object.keys(obj).forEach((key) => {
    const k = prefix ? `${prefix}[${key}]` : key;
    const value = obj[key];
    if (toObject) {
      if (value !== null && typeof value === 'object') {
        serialize(value, k, returnValue);
      } else {
        returnValue.push([k, value]);
      }
    } else {
      const insVal = (value !== null && typeof value === 'object')
        ? serialize(value, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(value)}`;
      // Don't push if it's empty
      if (insVal) {
        returnValue.push(insVal);
      }
    }
  });

  if (!toObject) {
    return returnValue.join('&');
  }
  return returnValue;
};
