import $ from 'jquery';
import basePath from 'dottom@common/base-path';

// webshop refresh after backbutton
if ($('.my_cartbox').length) {
  $.ajax({
    type: 'GET',
    url: `${basePath()}webshop/reload_cart`,
    dataType: 'html',
    success(html) {
      $('.my_cartbox').html(html);
    },
  });
}
