import loadTextEditor from '../text-editor';

export default (props = {
  lang: 'en',
  element: null,
}, label = '') => {
  const {
    element,
  } = props;
  const container = document.createElement('div');
  const textarea = element || document.createElement('textarea');
  if (label) {
    const labelElem = document.createElement('label');
    labelElem.textContent = label;
    if (textarea.id) {
      labelElem.htmlFor = textarea.id;
    }
    container.appendChild(labelElem);
  }
  container.appendChild(textarea);

  loadTextEditor(textarea, props);

  return container;
};
