import { copyObjects } from 'dottom@common/deep-copy';
import basic from './basic.json';
import extended from './extended.json';
import extra from './extra.json';
import brand from './brand.json';

export const COLORS = {
  basic,
  extended,
  extra,
  brand,
};

export const colorNames = copyObjects({}, ...Object.values(COLORS));
