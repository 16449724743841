export default class InvalidColorValueException extends Error {
  constructor(message) {
    super(message);
    this.name = 'InvalidColorValueException';
    this.message = message ? `The color value is invalid: ${message}` : 'The color value is invalid';
    // Use V8's native method if available, otherwise fallback
    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, InvalidColorValueException);
    } else {
      this.stack = (new Error()).stack;
    }
  }
}
