export const easeInSine = (x) => 1 - Math.cos((x * Math.PI) / 2);
export const easeOutSine = (x) => Math.sin((x * Math.PI) / 2);
export const easeInOutSine = (x) => -(Math.cos(Math.PI * x) - 1) / 2;
export const easeInQuad = (x) => x ** 2;
export const easeOutQuad = (x) => 1 - easeInQuad(1 - x);
export const easeInOutQuad = (x) => (x < 0.5 ? 2 * (x ** 2) : 1 - (-2 * x + 2) ** 2 / 2);

export const easeOutBounce = (x, bounces = 3, multiplierPerBounce = 0.5) => {
  const segments = [2];
  let totalLength = 1;

  for (let i = 0; i < Math.ceil(bounces); i += 1) {
    const currentLength = segments[i] * multiplierPerBounce;
    segments.push(currentLength);
    totalLength += currentLength * Math.min(bounces - i, 1);
  }

  const baseMultiplier = 1 / ((1 / totalLength) ** 2);

  let current = -1;

  for (let i = 0; i < segments.length; i += 1) {
    const segment = segments[i];
    current += segment;
    if (x < (current / totalLength)) {
      const newX = x - ((current - segment / 2) / totalLength);
      const adjustment = 1 - (baseMultiplier * ((segment / 2) / totalLength) ** 2);
      return baseMultiplier * newX ** 2 + adjustment;
    }
  }

  return 1;
};

export const easeInBounce = (x, bounces = 3, multiplierPerBounce = 0.5) => (
  1 - easeOutBounce(1 - x, bounces, multiplierPerBounce)
);

export const easeInOutBounce = (x, bounces = 3, multiplierPerBounce = 0.5) => {
  if (x < 0.5) {
    return easeInBounce(x * 2, bounces, multiplierPerBounce) / 2;
  }
  return easeOutBounce((x - 0.5) * 2, bounces, multiplierPerBounce) / 2 + 0.5;
};
