import onReady from 'dottom@common/on-ready';

onReady(() => {
  const loading = [];
  const sheets = document.querySelectorAll('link[data-layer]');
  if (sheets.length) {
    const style = document.createElement('style');
    const importSheets = () => {
      const importContent = loading.reduce((prev, current) => {
        if (current) {
          prev.push(`@import "${current.href}" layer(${current.dataset.layer});`);
        }
        return prev;
      }, []);
      style.textContent = importContent.join('\n');
    };
    document.head.prepend(style);
    sheets.forEach((sheet) => {
      const index = loading.length;
      loading[index] = null;
      if (sheet.sheet) {
        loading[index] = sheet;
        sheet.parentNode.removeChild(sheet);
      } else {
        const onLoad = () => {
          sheet.removeEventListener('load', onLoad);
          loading[index] = sheet;
          sheet.parentNode.removeChild(sheet);
          importSheets();
        };

        sheet.addEventListener('load', onLoad);
      }
    });
    importSheets();
  }
}, false);
