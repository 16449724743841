const basePath = require('dottom@common/base-path').default;

window.dottom = window.dottom ?? {};
const cmsDefaults = window.dottom.cmsDefaults ?? {};

if (typeof cmsDefaults.svgFile === 'undefined') {
  cmsDefaults.svgFile = `${basePath()}assets/images/icons/icons.svg`;
}

module.exports = cmsDefaults;
