import InvalidColorValueException from 'dottom@common/exceptions/InvalidColorValueException';
import { COLORS, colorNames } from 'dottom@common/helpers/colors/colornames';

/**
 * Converts from RGB to HSL
 * @param {number} red - The red value
 * @param {number} green - The green value
 * @param {number} blue - The blue value
 * @param {boolean} standardize - Whether to output standardized values instead of normalized
 * @param {boolean} standardizedInput - Whether the input is standardized or not
 */
const toHSL = (red, green, blue, standardize = false, standardizedInput = true) => {
  // Store the rgb color values as normalized values
  const r = standardizedInput ? red / 255 : red;
  const g = standardizedInput ? green / 255 : green;
  const b = standardizedInput ? blue / 255 : blue;

  // Figure out the delta (difference between the highest and lowest color value)
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;

  let h = 0;
  let s;
  const l = (max + min) / 2;

  if (min === max) {
    h = 0;
    s = 0;
  } else {
    s = delta / (l < 0.5 ? max + min : 2.0 - max - min);
    switch (max) {
      case r:
        h = (g - b) / delta + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / delta + 2;
        break;
      case b:
        h = (r - g) / delta + 4;
        break;
      default:
        break;
    }

    h /= 6;
  }
  if (standardize) {
    return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
  }
  return [h, s, l];
};

const hueToRGB = (p, q, arg3) => {
  let t = arg3;
  if (t < 0) {
    t += 1;
  }
  if (t > 1) {
    t -= 1;
  }
  if (t < 1 / 6) {
    return p + (q - p) * 6 * t;
  }
  if (t < 1 / 2) {
    return q;
  }
  if (t < 2 / 3) {
    return p + (q - p) * (2 / 3 - t) * 6;
  }
  return p;
};

const fromHSL = (hue, saturation, lightness, standardize = true, standardizedInput = false) => {
  let r;
  let g;
  let b;

  const h = standardizedInput ? hue / 360 : hue;
  const s = standardizedInput ? saturation / 100 : saturation;
  const l = Math.max(0, Math.min(1, standardizedInput ? lightness / 100 : lightness));

  if (s === 0) {
    r = l;
    g = l;
    b = l;
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hueToRGB(p, q, h + 1 / 3);
    g = hueToRGB(p, q, h);
    b = hueToRGB(p, q, h - 1 / 3);
  }

  if (standardize) {
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }
  return [r, g, b];
};

/**
 * Converts from RGB to HSL
 * @param {number} red - The red value
 * @param {number} green - The green value
 * @param {number} blue - The blue value
 * @param {boolean} standardize - Whether to output standardized values instead of normalized
 * @param {boolean} standardizedInput - Whether the input is standardized or not
 */
export const rgbToHSL = (red, green, blue, standardize = true, standardizedInput = true) => (
  toHSL(red, green, blue, standardize, standardizedInput)
);

/**
 *
 * @param {number} hue - The hue value
 * @param {number} saturation - The saturation value
 * @param {number} lightness - The lightness value
 * @param {boolean} standardize - Whether to output standardized values instead of normalized
 * @param {boolean} standardizedInput - Whether the input is standardized or not
 */
export const hslToRGB = (
  hue,
  saturation,
  lightness,
  standardize = true,
  standardizedInput = true,
) => (
  fromHSL(hue, saturation, lightness, standardize, standardizedInput)
);

/**
 * Retrieves the color values from the given color string
 * @param {string} color - The color
 * @param {boolean} withPrefix - Whether to include the original prefix
 * @return {object|array} - Either an object with the color values and the prefix, or
 * an array with the color values
 */
export const colorToValues = (color, withPrefix = false) => {
  const colorValues = [];
  let prefix = '';
  if (colorNames[color]) {
    colorValues.splice(0, 0, ...colorNames[color]);
    prefix = 'rgb';
  } else if (color.charAt(0) === '#') {
    const colorSegment = color.slice(1);
    if (colorSegment.length < 6) {
      colorValues.push(...colorSegment.match(/./g).map((value) => Number.parseInt(`0x${value}${value}`, 16)));
    } else {
      colorValues.push(...colorSegment.match(/.{1,2}/g).map((value) => Number.parseInt(`0x${value}`, 16)));
    }
    if (colorValues.length > 3) {
      colorValues.splice(4);
      colorValues[3] /= 255;
    }
    prefix = '#';
  } else {
    const hslMatch = color.match(/^\s*(hsla?)\s*\(\s*(\d+)\s*,\s*(\d+%|0)\s*,\s*(\d+%|0)\s*(?:,\s*(\d+(?:\.\d+)?)\s*)?\)$/);
    const match = color.match(/^\s*(.+?)\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*(\d+(?:\.\d+)?)\s*)?\)$/);
    if (!hslMatch && !match) {
      throw new InvalidColorValueException();
    }
    if (hslMatch) {
      // eslint-disable-next-line prefer-destructuring
      prefix = hslMatch[1];
      // eslint-disable-next-line prefer-destructuring
      const hue = +hslMatch[2];
      const saturation = +(hslMatch[3].slice(0, -1)) / 100;
      const lightness = +(hslMatch[4].slice(0, -1)) / 100;
      colorValues.push(...fromHSL(hue, saturation, lightness));
      if (hslMatch[5]) {
        colorValues.push(hslMatch[5]);
      }
    } else {
      colorValues.push(...match.slice(2).filter((value) => !!value).map((value) => +value));
      // eslint-disable-next-line prefer-destructuring
      prefix = match[1];
    }
  }

  if (withPrefix) {
    return {
      prefix,
      values: colorValues,
    };
  }
  return colorValues;
};

export const valuesToColor = (colorValues, prefix = '#') => {
  if (prefix === '#') {
    if (colorValues.length > 3) {
      if (colorValues[3] === 1) {
        colorValues.splice(3);
      } else {
        colorValues[3] = Math.round(colorValues[3] * 255);
      }
    }
    return colorValues.reduce((previousValue, currentValue) => `${previousValue}${currentValue.toString(16).padStart(2, '0')}`, '#');
  }
  let realPrefix = prefix;
  if (colorValues.length > 3) {
    colorValues[3] = Math.max(0, Math.min(1, colorValues[3]));
    if (colorValues[3] === 1 || colorValues.length < 4) {
      colorValues.splice(3);
      realPrefix = prefix.replace(/a$/, '');
    } else {
      colorValues.splice(4);
      if (prefix.slice(-1) !== 'a') {
        realPrefix = `${realPrefix}a`;
      }
    }
  }
  if (prefix.startsWith('hsl')) {
    const hsl = toHSL(...colorValues.slice(0, 3));
    hsl[1] = `${(hsl[1] * 100).toFixed(1)}%`;
    hsl[2] = `${(hsl[2] * 100).toFixed(1)}%`;
    if (colorValues.length > 3) {
      hsl.push(...colorValues.slice(3));
    }
  }

  return `${realPrefix}(${colorValues.join(', ')})`;
};

export const getBrightness = (color, normalized = true) => {
  const rgb = colorToValues(color);
  const returnValue = (rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114);
  if (normalized) {
    return (returnValue / 255) * 100;
  }
  return returnValue;
};

export const getLuminance = (color) => {
  const rgb = colorToValues(color);
  return (0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]) / 255;
};

export const setBrightness = (color, amount) => {
  const {
    values: colorValues,
    prefix,
  } = colorToValues(color, true);

  const rest = colorValues.splice(3);
  const hsl = toHSL(...colorValues.splice(0));
  hsl[2] += amount;
  colorValues.push(...fromHSL(...hsl));

  colorValues.push(...rest);

  return valuesToColor(colorValues, prefix || '#');
};

export const colorDifference = (color1, color2) => {
  const rgb1 = colorToValues(color1);
  const rgb2 = colorToValues(color2);

  return Math.abs(rgb1[0] - rgb2[0]) + Math.abs(rgb1[1] - rgb2[1]) + Math.abs(rgb1[2] - rgb2[2]);
};

export const getBrightnessDifference = (color1, color2) => {
  const rgb1 = colorToValues(color1);
  const rgb2 = colorToValues(color2);
  return Math.abs(getBrightness(rgb1, false) - getBrightness(rgb2, false));
};

export const getLuminosityContrast = (color1, color2) => {
  const luminosity1 = getLuminance(color1);
  const luminosity2 = getLuminance(color2);
  if (luminosity1 > luminosity2) {
    return (luminosity1 + 0.05) / (luminosity2 + 0.05);
  }
  return (luminosity2 + 0.05) / (luminosity1 + 0.05);
};

export const getContrastScore = (color1, color2) => (
  Math.min(1, colorDifference(color1, color2) / 500)
    + Math.min(1, getBrightnessDifference(color1, color2) / 125)
    + Math.min(1, getLuminosityContrast(color1, color2) / 5)
);

export const requiresContrastColor = (color, dark = '#000', light = '#fff', threshold = 78) => {
  if (color === 'dark') {
    return true;
  }
  if (color === 'light') {
    return false;
  }
  if (threshold === -1) {
    return getContrastScore(color, light) < getContrastScore(color, dark);
  }
  const brightness = getBrightness(color);
  if (threshold > 0) {
    return brightness < threshold;
  }
  let brightnessDark = getBrightness(dark);
  let brightnessLight = getBrightness(light);
  if (brightnessDark > brightnessLight) {
    const tempBrightness = brightnessDark;
    brightnessDark = brightnessLight;
    brightnessLight = tempBrightness;
  }
  return Math.abs(brightness - brightnessDark) > Math.abs(brightness - brightnessLight);
};

export const values = {
  basic: COLORS.basic,
  extended: COLORS.extended,
  brand: COLORS.brand,
};
