const CMS_EVENTS = require('dottom@shared/events');

const onReady = (callback, persist = true) => {
  if (document.readyState === 'complete') {
    window.setTimeout(callback);
    if (persist) {
      window.addEventListener(CMS_EVENTS.EVENTS.CMS_PAGE_LOAD, callback);
    }
  } else {
    const onReadyCB = () => {
      document.removeEventListener('DOMContentLoaded', onReadyCB);
      window.removeEventListener('load', onReadyCB);
      if (persist) {
        window.removeEventListener(CMS_EVENTS.EVENTS.CMS_PAGE_LOAD, onReadyCB);
        window.addEventListener(CMS_EVENTS.EVENTS.CMS_PAGE_LOAD, callback);
      }
      callback();
    };
    document.addEventListener('DOMContentLoaded', onReadyCB);
    window.addEventListener('load', onReadyCB);
    if (persist) {
      window.addEventListener(CMS_EVENTS.EVENTS.CMS_PAGE_LOAD, onReadyCB);
    }
  }
};

export default onReady;
