import onReady from 'dottom@common/on-ready';

const loaded = {};

export function createCSSLoader(href, before = null, media = 'all') {
  const stylesheet = document.createElement('link');
  const ref = before ?? [...(document.body || document.getElementsByTagName('head')[0]).childNodes].slice(-1)[0];
  stylesheet.rel = 'stylesheet';
  stylesheet.href = href;

  onReady(() => {
    ref.parentNode.insertBefore(stylesheet, before ? ref : ref.nextSibling);
  }, false);

  function cssIsLoaded() {
    stylesheet.removeEventListener('load', cssIsLoaded);
    stylesheet.removeEventListener('cssLoaded', cssIsLoaded);
    stylesheet.media = media;
  }

  stylesheet.addEventListener('load', cssIsLoaded);
  stylesheet.addEventListener('cssLoaded', cssIsLoaded);

  const loadInterval = setInterval(() => {
    const resolvedHref = stylesheet.href;
    const styleSheets = [...document.styleSheets];
    styleSheets.every((sheet) => {
      if (sheet.href === resolvedHref) {
        stylesheet.dispatchEvent(new CustomEvent('cssLoaded'));
        clearInterval(loadInterval);
        return false;
      }
      return true;
    });
  });

  return stylesheet;
}

function cssLoad(url, before = null) {
  // eslint-disable-next-line camelcase
  const trueUrl = (__webpack_public_path__ !== '/assets/' && /^\/assets\//.test(url)
    ? url.replace(/^\/assets\//, __webpack_public_path__)
    : url
  );
  if (loaded[trueUrl]) {
    return loaded[trueUrl].promise;
  }

  const promise = new Promise((resolve, reject) => {
    loaded[trueUrl] = {
      resolve,
      reject,
    };
  });

  loaded[trueUrl].promise = promise;

  const existingLink = document.querySelector(`link[href$="${trueUrl}"]`);

  if (existingLink && existingLink.sheet && existingLink.sheet.cssRules) {
    loaded[trueUrl].resolve();
    return promise;
  }

  const stylesheet = existingLink ?? createCSSLoader(trueUrl, before);

  let timeout;
  let onStylesheetComplete;

  const onStylesheetSuccess = () => {
    loaded[trueUrl].resolve();
    onStylesheetComplete();
  };

  const onStylesheetError = () => {
    loaded[trueUrl].reject();
    onStylesheetComplete();
  };

  onStylesheetComplete = () => {
    stylesheet.removeEventListener('load', onStylesheetSuccess);
    stylesheet.removeEventListener('error', onStylesheetError);
    clearTimeout(timeout);
  };

  stylesheet.addEventListener('load', onStylesheetSuccess);
  stylesheet.addEventListener('error', onStylesheetError);
  timeout = setTimeout(onStylesheetError, 120000);

  return promise;
}

export default cssLoad;
