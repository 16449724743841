import { fn } from 'jquery';
import dynamicLoad from 'dottom@common/dynamic-loading';
import cssLoad from 'dottom@common/css-load';

const loadModule = async (moduleName, verifyFunction = null) => {
  const externalModule = EXTERNAL_MODULES[moduleName];

  const loader = [];

  if (externalModule.css) {
    externalModule.css.forEach((file) => {
      loader.push(cssLoad(file));
    });
  }

  if (externalModule.js) {
    externalModule.js.forEach((file) => {
      loader.push(dynamicLoad(file, verifyFunction));
    });
  }

  await Promise.all(loader);
};

export const loadSelect2 = () => loadModule('Select2', () => !!fn.select2);
export const loadVue = async () => {
  await loadModule('Vue', () => !!window.Vue);
  await loadModule('Vuex', () => !!window.Vuex);
};

export default loadModule;
